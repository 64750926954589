<template>
    <div>
        <layout :title="translation.headings.active_assessments">
            <template v-slot:contents>
                <div v-if="assessments.length === 0">
                    <div class="rounded-md bg-blue-50 p-4">
                        <div :class="rtl('flex-row-reverse')" class="flex">
                            <div class="flex-shrink-0">
                                <svg
                                    class="h-5 w-5 text-blue-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div :class="rtl('mr-3 text-right') + ltr('ml-3')">
                                <h3
                                    role="heading"
                                    class="text-sm leading-5 font-medium text-blue-800"
                                >
                                    {{
                                        translation.headings
                                            .no_active_assessments
                                    }}
                                </h3>
                                <div
                                    role="dialog"
                                    class="mt-2 text-sm leading-5 text-blue-700"
                                >
                                    <p>
                                        {{
                                            translation.messages
                                                .no_active_assessments
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul
                    v-else
                    class="grid gap-6 grid-cols-2"
                    test-ref="schedule-list"
                >
                    <li
                        v-for="assessment in assessments"
                        test-ref="schedule"
                        class="col-span-2 bg-white rounded-lg shadow-container"
                    >
                        <div class="bg-white px-4 pt-5 sm:px-6 rounded-t-md">
                            <h1
                                :class="rtl('text-right')"
                                class="text-lg leading-6 font-medium text-gray-900 border-b border-gray-500 pb-4"
                            >
                                {{ assessment.version.assessment.title }}
                                <span class="block font-normal text-sm"
                                    >Group:
                                    <span class="text-gray-500">{{
                                        assessment.group.name
                                    }}</span></span
                                >
                            </h1>
                        </div>
                        <div class="w-full p-6">
                            <div
                                class="rounded-md bg-blue-50 p-4 mb-8"
                                v-if="hideAssessmentWindowCloses !== true"
                            >
                                <div
                                    :class="rtl('flex-row-reverse')"
                                    class="flex"
                                >
                                    <div class="flex-shrink-0">
                                        <svg
                                            class="h-5 w-5 text-blue-400"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <div :class="rtl('mr-3') + ltr('ml-3')">
                                        <p
                                            class="text-sm leading-5 text-blue-700"
                                        >
                                            {{
                                                trans(
                                                    translation.messages
                                                        .assessment_window_closes,
                                                    {
                                                        ":time":
                                                            endsAtCountDown(
                                                                assessment
                                                            ),
                                                    }
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="flex items-center justify-between space-x-6"
                            >
                                <div class="flex-1 truncate">
                                    <div class="flex items-center space-x-3">
                                        <p
                                            class="text-gray-900 text-sm leading-5 font-medium truncate flex"
                                        >
                                            <svg
                                                class="w-5 h-5 mr-2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                />
                                            </svg>
                                            <span>{{
                                                startsAt(assessment)
                                            }}</span>
                                        </p>
                                        <span
                                            v-if="
                                                assessment.group
                                                    .assessment_schedule
                                                    .is_practise
                                            "
                                            class="flex-shrink-0 inline-block px-2 py-0.5 text-yellow-800 text-xs leading-4 font-medium bg-yellow-100 rounded-full"
                                        >
                                            {{
                                                translation.labels
                                                    .practice_assessment
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                assessment.group
                                                    .assessment_schedule
                                                    .proctored
                                            "
                                            class="flex-shrink-0 inline-block px-2 py-0.5 text-yellow-800 text-xs leading-4 font-medium bg-yellow-100 rounded-full"
                                        >
                                            Proctored
                                        </span>
                                    </div>
                                    <p
                                        class="mt-1 text-gray-500 text-sm leading-5 truncate"
                                    >
                                        {{
                                            assessment.group.assessment_schedule
                                                .name
                                        }}
                                        |
                                        {{
                                            assessment.version.assessment.title
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="flex items-center text-castleton-green rounded-full px-2 py-1 bg-aero"
                                >
                                    <svg
                                        class="h-5 w-h mr-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                    <span class="text-sm">{{
                                        duration(assessment)
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="-mt-px flex">
                                <div class="w-0 flex-1 flex">
                                    <a
                                        v-if="requiresSafeBrowser(assessment)"
                                        :test-ref="'open-seb-' + assessment.id"
                                        :class="rtl('flex-row-reverse')"
                                        @click.prevent="
                                            openSafeBrowser(assessment)
                                        "
                                        href="#"
                                        class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 leading-5 text-white bg-green-700 font-medium border border-transparent rounded-b-lg hover:bg-green-800 focus:outline-none focus:shadow-outline-green focus:border-green-300 focus:z-10 transition ease-in-out duration-150 focus:ring-2 focus:ring-green-300 focus:bg-green-600"
                                        target="_blank"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-6 h-6"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                                            />
                                        </svg>
                                        <span
                                            :class="rtl('mr-3') + ltr('ml-3')"
                                            >{{
                                                translation.buttons_links
                                                    .open_safe_browser
                                            }}</span
                                        >
                                    </a>

                                    <a
                                        v-else
                                        :class="rtl('flex-row-reverse')"
                                        :test-ref="
                                            'sit-assessment-' + assessment.id
                                        "
                                        @click.prevent="
                                            sitAssessment(assessment)
                                        "
                                        href="#"
                                        class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 leading-5 text-white bg-green-700 font-medium border border-transparent rounded-b-lg hover:bg-green-800 focus:outline-none focus:shadow-outline-green focus:border-green-300 focus:z-10 transition ease-in-out duration-150 focus:ring-2 focus:ring-green-300 focus:bg-green-600"
                                    >
                                        <svg
                                            class="w-5 h-5 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                            />
                                        </svg>
                                        <span
                                            v-if="
                                                assessment.candidate_started_at ===
                                                null
                                            "
                                            :class="rtl('mr-3') + ltr('ml-3')"
                                            >{{
                                                translation.buttons_links
                                                    .sit_assessment
                                            }}</span
                                        >
                                        <span
                                            v-else
                                            :class="rtl('mr-3') + ltr('ml-3')"
                                            >{{
                                                translation.buttons_links
                                                    .resume_assessment
                                            }}</span
                                        >
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </template>
        </layout>

        <SafeBrowserModal
            :open="safeBrowserModal.open"
            @close="safeBrowserModal.open = false"
        />
    </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Loader from "@/components/Loader.vue";
import ComputedBus from "@/utils/computed-bus.js";
import MethodsBus from "@/utils/methods-bus.js";
import SafeBrowserModal from "@/components/SafeBrowserModal.vue";

export default {
    beforeRouteEnter(to, from, next) {
        window.bus.$gates.BeforeEnterHome(to, from, next);
    },
    components: {
        SafeBrowserModal,
        Layout,
        Loader,
    },
    data() {
        return {
            seconds_lapsed: 0,
            safeBrowserModal: {
                open: false,
            },
            hideAssessmentWindowCloses: import.meta.env.VITE_HIDE_ASSESSMENT_WINDOW_CLOSES === "true",
        };
    },
    computed: {
        assessments() {
            return this.$store.state.assessments.active;
        },
        ...ComputedBus,
    },
    mounted() {
        this.$saves.checkLocalSaves();
        this.$global.disconnectFromChannels();
        setInterval(() => {
            this.seconds_lapsed++;
        }, 1000);
    },
    methods: {
        ...MethodsBus,
        requiresSafeBrowser(session) {
            return (
                this.$global.sessionNeedsSafeBrowser(session) &&
                !this.$global.browserCanAccessSecureAssessments() &&
                import.meta.env.VITE_ENABLE_LAUNCH_SEB
            );
        },
        openSafeBrowser(session) {
            const url =
                session.group.assessment_schedule.seb_config_url ||
                import.meta.env.VITE_DEFAULT_SEB_CONFIG_URL;

            this.$debug.log("SEB Config: " + url);

            window.location.replace("seb://" + url);

            this.safeBrowserModal.open = true;
        },
    },
};
</script>
