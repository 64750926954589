import { createRouter, createWebHistory } from "vue-router";
import ActiveAssessments from "../views/ActiveAssessments.vue";
import SandboxSections from "../views/SandboxSections.vue";
import Proctor from "../views/Proctor.vue";
import Assessment from "../views/Assessment.vue";
import ProctoredAssessment from "../views/ProctoredAssessment.vue";
import ReviewAssessment from "../views/ReviewAssessment.vue";
import WaitingRoom from "../views/WaitingRoom.vue";
import CompletedAssessments from "../views/CompletedAssessments.vue";
import UpcomingAssessments from "../views/UpcomingAssessments.vue";
import Login from "../views/Login.vue";
import D2CAuth from "../views/D2CAuth.vue";
import Register from "../views/Register.vue";
import ForgottenPassword from "../views/ForgottenPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import RouteNotFound from "../views/RouteNotFound.vue";
import D2CReport from "../views/D2CReport.vue";

const unauthorizedRoutes = [
    "login",
    "register",
    "forgotten-password",
    "password/reset",
];

export const isUnauthorizedRoute = (prefix="") => {
    for (let route of unauthorizedRoutes) {
        if (window.location.pathname.includes(`${prefix}/${route}`)) {
            return true;
        }
    }
    return false;
};

const routes = () => {
    const isSandbox =
        window.location.pathname.includes("sandbox") &&
        import.meta.env.VITE_SANDBOX_ENABLED === "true";
    if (isSandbox) {
        return [
            {
                path: "/sandbox/login",
                name: "login",
                component: Login,
                meta: { title: "Login - Assessment Player (Sandbox)" },
            },
            {
                path: "/sandbox/register",
                name: "register",
                component: Register,
                meta: { title: "Register - Assessment Player (Sandbox)" },
            },
            {
                path: "/sandbox/forgotten-password",
                name: "forgotten-password",
                component: ForgottenPassword,
                meta: {
                    title: "Forgotten password - Assessment Player (Sandbox)",
                },
            },
            {
                path: "/sandbox/password/reset/:token",
                name: "password-reset",
                component: ResetPassword,
                meta: { title: "Reset password - Assessment Player (Sandbox)" },
            },
            {
                path: "/sandbox",
                name: "home",
                component: SandboxSections,
                meta: {
                    title: "Active assessments - Assessment Player (Sandbox)",
                },
            },
            {
                path: "/sandbox/assessment/:id",
                name: "assessment",
                component: Assessment,
                meta: { title: "Assessment Player (Sandbox)" },
            },
        ];
    }
    let otherRoutes = [];
    if (import.meta.env.VITE_REGISTRATION_ENABLED) {
        otherRoutes.push({
            path: "/register",
            name: "register",
            component: Register,
            meta: { title: "Register - Assessment Player" },
        });
    }
    if (import.meta.env.VITE_FORGOT_PASSWORD_ENABLED) {
        otherRoutes.push(
            {
                path: "/forgotten-password",
                name: "forgotten-password",
                component: ForgottenPassword,
                meta: { title: "Forgotten password - Assessment Player" },
            },
            {
                path: "/password/reset/:token",
                name: "password-reset",
                component: ResetPassword,
                meta: { title: "Reset password - Assessment Player" },
            }
        );
    }
    return [
        {
            path: "/",
            name: "home",
            component: ActiveAssessments,
            meta: { title: "Active assessments - Assessment Player" },
        },
        {
            path: "/proctor",
            name: "proctor",
            component: Proctor,
            meta: { title: "Proctor - Assessment Player" },
        },
        { path: "/assessment/:id", name: "assessment", component: Assessment },
        {
            path: "/assessment/proctored/:token",
            name: "proctored-assessment",
            component: ProctoredAssessment,
            meta: { title: "Proctored assessment - Assessment Player" },
        },
        {
            path: "/review-assessment",
            name: "review-assessment",
            component: ReviewAssessment,
            meta: { title: "Review assessment - Assessment Player" },
        },
        {
            path: "/waiting-room",
            name: "waiting-room",
            component: WaitingRoom,
            meta: { title: "Waiting room - Assessment Player" },
        },
        {
            path: "/completed-assessments",
            name: "completed-assessments",
            component: CompletedAssessments,
            meta: { title: "Completed assessments - Assessment Player" },
        },
        {
            path: "/upcoming-assessments",
            name: "upcoming-assessments",
            component: UpcomingAssessments,
            meta: { title: "Upcoming assessments - Assessment Player" },
        },
        {
            path: "/login",
            name: "login",
            component: Login,
            meta: { title: "Login - Assessment Player" },
        },
        {
            path: "/d2c/:client_id/:assessment_schedule_group_id/:candidate_id/:assessment_version_id/:hash",
            name: "d2c-auth",
            component: D2CAuth,
            meta: { title: "Login - Assessment Player" },
        },
        {
            path: "/d2c/:client_id/:assessment_schedule_group_id/:candidate_id/:assessment_version_id/:hash/report/:session_id",
            name: "d2c-report",
            component: D2CReport,
            meta: { title: "Report - Assessment Player" },
        },
        { path: "/:pathMatch(.*)*", component: RouteNotFound },
        ...otherRoutes,
    ];
};

const router = createRouter({
    history: createWebHistory(),
    routes: routes(),
});

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }
    window.bus.$gates.BeforeUsingRealUserRoutes(to, from, next);
});

export default router;
