import { createStore } from 'vuex'
import VuexPersist from "vuex-persistedstate";
import SecureLS from "secure-ls";

const storageKey = 'mod-candidate';
const ls = new SecureLS({ isCompression: false });

const PersistentStorage = new VuexPersist({
    key: storageKey,
    storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
    }
});

export default createStore({
    plugins: [PersistentStorage],
    state: {
        should_load_learnosity: true,
        player_config: {},
        fetch_failed: false,
        assessment_blob: {},
        logged_in_at: null,
        user: null,
        token: null,
        page_loading: false,
        assessment: null,
        state: 'initialising',
        status: 'offline',
        assessments: {
            upcoming: [],
            completed: [],
            active: [],
        },
        notifications: [],
        translatables: {},
        assume_safe_browser: false,
        sandboxMenu: [],
    },
    mutations: {
        setSandboxMenu(state,data) {
            state.sandboxMenu = data;
        },
        setBlobAsDispatched(state) {

        },
        setShouldLoadLearnosity(state, boolean) {
            state.should_load_learnosity = boolean;
        },
        setPlayerConfig(state, boolean) {
            state.player_config = boolean;
        },
        setAssumeSafeBrowser(state, boolean) {
            state.assume_safe_browser = boolean;
        },
        setUser(state, object) {
            state.user = object;
        },
        setToken(state, token) {
            state.token = token;
        },
        setPageLoading(state, boolean) {
            state.page_loading = boolean;
        },
        setLoggedInAtTime(state, time) {
            state.logged_in_at = time;
        },
        setUpcomingAssessments(state, assessments) {
            state.assessments.upcoming = assessments;
        },
        setActiveAssessments(state, assessments) {
            state.assessments.active = assessments;
        },
        setCompletedAssessments(state, assessments) {
            state.assessments.completed = assessments;
        },
        setSandboxSections(state, sections) {
            state.assessments.sandbox = { sections };
        },
        setAssessment(state, assessment) {
            state.assessment = assessment;
        },
        setAssessmentBlob(state, data) {
            state.assessment_blob[data.session_id] = data;
        },
        setState(state, newState) {
            state.state = newState;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setFetchFailed(state, bool) {
            state.fetch_failed = bool;
        },
        addNotification(state, notification) {
            state.notifications.push(Object.assign(
                {
                    id: '_' + Math.random().toString(36).substr(2, 9),
                    type: 'info',
                    persistent: false,
                    heading: 'Info',
                    message: '',
                    notified: false
                },
                notification
            ));
        },
        removeNotification(state, notification) {
            state.notifications.splice(state.notifications.indexOf(notification), 1);
        },
        setTranslatables(state, data) {
            state.translatables = data;
        },
        clear(state, instance) {
            state.assessments = {
                upcoming: [],
                completed: [],
                active: [],
            };
            state.d2c = false;
            state.fetch_failed = false;
            state.logged_in_at = null;
            state.user = null;
            state.assessment = null;
            state.notifications = [];
            state.assume_safe_browser = false;
            if(instance) {
                instance.$echo.disconnect();
            }
        }
    }
});
