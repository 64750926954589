import {isUnauthorizedRoute} from '../router'

export default {

    data: {
        app: null,
        last_check: null,
        tickers: [],
        channels: {},
        isSandbox: window.location.pathname.includes("sandbox")
    },

    /**
     * App
     * @param app
     */
    app(app) {
        return this.data.app = app;
    },

    /**
     * Load the languages
     * @param locale
     * @param callback
     */
    translatables(locale, callback) {
        locale = locale || '';
        callback = callback || function () { };
        this.data.app.$axios.get(endpoint('/api/translatables/' + locale))
            .then(response => {
                this.data.app.$store.commit('setTranslatables', response.data);
                callback();
            })
            .catch(() => {
                callback();
            });
    },

    /**
     * Load sandbox menu
     */
    getSandboxMenu() {
        this.data.app.$axios.get(endpoint('/api/candidates/sandbox-menu'))
            .then(response => {
                this.data.app.$store.commit('setSandboxMenu', response.data);
            })
            .catch((error) => {
                if(error.response.status === 401) {
                    if(!isUnauthorizedRoute()){
                        this.data.app.$debug.log(`$global.getUser() is unauthorised route.`);
                        this.data.app.$store.commit('clear', this.data.app);
                        this.data.app.$router.push({
                            name: 'login'
                        })
                    }
                }
            });
    },

    /**
     * Get the users details from the server.
     * @param vue
     */
    getUser(vue) {
        this.data.app.$debug.log(`Calling $global.getUser()`);
        this.setXSRFToken(() => {
            this.data.app.$axios.get(endpoint('/api/candidates/user')).then(response => {
                this.data.app.$debug.log(`Data returned from $global.getUser()`);
                this.data.app.$store.commit('setFetchFailed', false);
                this.data.app.$store.commit('setUser', response.data);
            }).catch((error) => {
                this.data.app.$debug.log(`$global.getUser() failed with status code ${error.response.status}`);
                this.disconnectFromChannels(true);
                if(error.response.status === 401) {
                    if(!isUnauthorizedRoute()){
                        this.data.app.$debug.log(`$global.getUser() is unauthorised route.`);
                        this.data.app.$store.commit('clear', this.data.app);
                        this.data.app.$router.push({
                            name: 'login'
                        })
                    }
                } else {
                    this.data.app.$store.commit('setFetchFailed', true);
                }
            });
        });
    },

    /**
     * Register candidate channel
     */
    registerCandidateChannel(callback) {
        callback = callback || function(){};
        if(this.data.app.$store.state.user) {
            this.data.app.$debug.log(`Registering private group App.Candidate.${this.data.app.$store.state.user.id}`);
            this.registerChannel('private', `App.Candidate.${this.data.app.$store.state.user.id}`, 'private', callback);
        }
    },

    /**
     * Register/retrieve a websocket channel.
     * @param name
     * @param callback
     * @returns {*}
     */
    channel(name, callback) {
        callback = callback || function(){};

        if(typeof this.data.channels[name] !== 'undefined' && callback) {
            callback(this.data.channels[name].socket);
        }
    },

    /**
     * Register a websocket channel.
     * @param name
     * @param channel
     * @param type
     * @param subscribed
     */
    registerChannel(name, channel, type, subscribed) {
        channel = channel || null;
        type = type || null;
        subscribed = subscribed || function(){};

        if(typeof this.data.channels[name] === 'undefined' && channel) {
            this.data.channels[name] = {
                listeners: [],
                socket: this.initSocket(channel, type).on('pusher:subscription_succeeded', () => {
                    subscribed();
                }),
                channel,
                type
            };
        }
    },

    /**
     * Register whisper listener
     * @param name
     * @param channel
     * @param callback
     */
    registerWhisperListener(name, channel, callback) {
        if(typeof this.data.channels[channel] !== 'undefined' && callback) {
            if(this.data.channels[channel].listeners.indexOf(name) === -1) {
                this.data.channels[channel].listeners.push(name);
                this.data.channels[channel].socket.listenForWhisper(name, (message) => {
                    callback(message, this.data.channels[channel].socket);
                });
            }
        }
    },

    /**
     * Register listener
     * @param name
     * @param channel
     * @param callback
     */
    registerListener(name, channel, callback) {
        if(typeof this.data.channels[channel] !== 'undefined' && callback) {
            if(this.data.channels[channel].listeners.indexOf(name) === -1) {
                this.data.channels[channel].listeners.push(name);
                this.data.channels[channel].socket.listen(name, (message) => {
                    callback(message, this.data.channels[channel].socket);
                });
            }
        }
    },

    /**
     * Initialise the type of channel required.
     * @param channel
     * @param type
     * @returns {Channel|*}
     */
    initSocket(channel, type) {
        switch (type) {
            case 'presence':
                return this.data.app.$echo.join(channel);
            case 'private':
                return this.data.app.$echo.private(channel);
        }
    },

    /**
     * Iterate through the the channels and disconnect them all.
     * @param disconnectCandidate
     */
    disconnectFromChannels(disconnectCandidate) {
        disconnectCandidate = typeof disconnectCandidate === 'undefined' ? false : disconnectCandidate;
        this.data.app.$debug.log('Disconnecting from all web socket channels [including private? ' + disconnectCandidate + ']');
        for (let i in this.data.channels) {
            if (i !== 'presence-candidate' || (i === 'presence-candidate' && disconnectCandidate)) {
                if (this.data.channels.hasOwnProperty(i)) {
                    let channel = this.data.channels[i];
                    this.data.app.$echo.leave(channel.channel);
                    delete this.data.channels[i];
                }
            }
        }
    },

    /**
     * Starts the animation frame
     */
    startInterval() {
        window.requestAnimationFrame((timestamp) => {
            this.interval(timestamp);
        });
    },

    /**
     * Creates a 1 second interval
     * @param timestamp
     */
    interval(timestamp) {
        timestamp = Math.floor(Number(timestamp.toFixed(0)) / 1000);
        if (timestamp % 1 === 0 && this.data.last_check !== timestamp) {
            this.data.last_check = timestamp;
            for (let i in this.data.tickers) {
                if (this.data.tickers.hasOwnProperty(i)) {
                    this.data.tickers[i](timestamp, () => {
                        delete this.data.tickers[i];
                    });
                }
            }
        }
        this.startInterval();
    },

    /**
     * Store a ticker
     * @param name
     * @param callback
     */
    ticker(name, callback) {
        if (typeof this.data.tickers[name] !== 'undefined') {
            delete this.data.tickers[name];
        }
        this.data.tickers[name] = callback;
    },

    /**
     * Returns an object of time based from a start time.
     * @param start
     * @param callback
     */
    timeUntil(start, callback) {
        let countDownDate = new Date(start).getTime();
        let now = new Date().getTime();
        let distance = countDownDate - now;

        callback({
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
            completed: distance <= 0
        })
    },

    /**
     * Load the Learnosity javascript API.
     * @param callback
     * @param assessment
     */

    loadLearnosity(callback, assessment, to, from) {
        assessment = assessment || this.data.app.$store.state.assessment;
        if(typeof window.LearnosityItems !== 'undefined') {
            let script = document.querySelector('[data-lrn-dir]');
            if(script) {
                if(script.getAttribute('data-lrn-dir') !== assessment.version.direction) {
                    window.location = to.fullPath;
                }
            }
            callback();
        } else {
            let script = document.createElement('script');
            script.src = assessment.learnosity_data.script;
            script.setAttribute('data-lrn-dir',  assessment.version.direction);
            script.onload = callback;
            document.head.appendChild(script);
        }
    },

    /**
     * Check to see if the browser is "safe exam browser"
     * @ref https://safeexambrowser.org/developer/seb-integration.html
     * @returns {boolean}
     */
    isSafeExamBrowser() {
        return navigator.userAgent.toLowerCase().includes('seb');
    },

    /**
     * Check to see if the browser is chrome in kiosk mode.
     * An custom alternative to the safe exam browser for chromebooks where the safe browser is not installable.
     *
     * @returns {boolean}
     */
    isKioskModeBrowser() {
        return navigator.userAgent.toLowerCase().includes('securekioskmode');
    },

    /**
     * Check to see if we should assume the app is running in a safe browser. This would have been set
     * to `true` by a check based on a query paramter e.g `?safe` and is a fallback for if no other safe
     * browser method is working for the centre.
     *
     * @returns {boolean}
     */
    shouldAssumeSafeBrowser() {
        return this.data.app.$store.state.assume_safe_browser;
    },

    /**
     * Check to see if user can access assessment
     * @returns {boolean}
     */
    canAccessAssessment() {
        return ! (
            this.sessionNeedsSafeBrowser(this.data.app.$store.state.assessment)
            && ! this.browserCanAccessSecureAssessments()
        );
    },

    /**
     * Check if a session requires the Safe browser.
     * @param assessment
     * @returns {boolean}
     */
    sessionNeedsSafeBrowser(session) {
        return session.group.assessment_schedule.browser_lockdown_type === 'SEB';
    },

    /**
     * If the browser can access a secure assessment.
     * Checks multiple conditions to determine if browser is secure.
     * @returns {boolean}
     */
    browserCanAccessSecureAssessments() {
        return this.isSafeExamBrowser() || this.isKioskModeBrowser() || this.shouldAssumeSafeBrowser();
    },

    /**
     * Get a fresh CSRF token
     * @param callback
     */
    setXSRFToken(callback) {
        this.data.app.$axios.get(endpoint('/sanctum/csrf-cookie'))
            .then(callback);
    },

    /**
     * Add success notification.
     * @param object
     */
    addSuccessNotification(object) {
        this.data.app.$store.commit('addNotification', Object.assign({
            type: 'success',
            heading: this.data.app.$store.state.translatables.translation.notifications.headers.success
        }, object))
    },

    /**
     * Add notification.
     * @param object
     */
    addNotification(object) {
        this.data.app.$store.commit('addNotification', Object.assign({
            type: this.data.app.$store.state.translatables.translation.notifications.headers.info
        }, object))
    },

    /**
     * Add error notification.
     * @param object
     */
    addErrorNotification(object) {
        this.data.app.$store.commit('addNotification', Object.assign({
            type: 'error',
            heading: this.data.app.$store.state.translatables.translation.notifications.headers.error
        }, object))
    },

    /**
     * Error
     * @param message
     * @param heading
     */
    error(message, heading) {
        this.data.app.$debug.log('Error message: ', { message, heading });
        this.addErrorNotification({
            heading: heading || 'Error',
            message
        });
    },

    /**
     * Error
     * @param message
     * @param heading
     */
    success(message, heading) {
        this.data.app.$debug.log('Success message: ', { message, heading });
        this.addSuccessNotification({
            heading: heading || 'Success',
            message
        });
    },

    /**
     * Set the page load to display.
     */
    pageIsLoading() {
        this.data.app.$debug.log('Page is loading');
        this.data.app.$store.commit('setPageLoading', true);
    },

    /**
     * Set the page loader to hide.
     */
    pageIsNotLoading() {
        this.data.app.$debug.log('Page is not loading');
        this.data.app.$store.commit('setPageLoading', false);
    },

    /**
     * Global AXIOS Error Handler
     * @param error
     * @param showErrors
     * @param inst
     * @returns {number}
     */
    globalAXIOSErrorHandler(error, showErrors, inst) {
        showErrors = typeof showErrors === 'undefined' ? true : showErrors;

        if (!this.data.app) {
            return setTimeout(() => {
                this.globalAXIOSErrorHandler(error, showErrors, inst);
            }, 100);
        }

        this.data.app.$debug.log('Global error handler:', error);

        switch (error.response.status) {
            case 401:
                if (error.response.data.error && showErrors) {
                    this.error(error.response.data.error);
                }
                this.data.app.$store.commit('clear', this.data.app);
                window.location.reload();
                break;
            case 403:
                this.error('Unauthorised. Please log in.');
                break;
            case 419:
                // CSRF token error
                this.error('CSRF token error. Please manually refresh your browser.');
                break;
            case 422:
                // Validation
                for (let i in error.response.data.errors) {
                    if (Object.prototype.hasOwnProperty.call(error.response.data.errors, i)) {
                        if (showErrors) {
                            this.error(error.response.data.errors[i][0]);
                        } else if (inst) {
                            inst.errors[i] = error.response.data.errors[i][0];
                        }
                    }
                }
                break;
            case 500:
                if (showErrors) {
                    this.error(error.response.data);
                }
                break;
        }

        this.pageIsNotLoading();
    },

    fromNow(date) {
        let date_future = new Date(date);
        let date_now = new Date();
        let seconds = Math.floor((date_future - (date_now))/1000);
        let minutes = Math.floor(seconds/60);
        let hours = Math.floor(minutes/60);
        let days = Math.floor(hours/24);

        hours = hours-(days*24);
        minutes = minutes-(days*24*60)-(hours*60);
        seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

        return { days, hours, minutes, seconds };
    },

    /**
     * From Now Formatted
     * @param date
     * @returns {string}
     */
    fromNowFormatted(date) {
        let fromNow = this.fromNow(date);

        if(fromNow.days < 0) return `0 ${this.data.app.$store.state.translatables.translation.datetime.seconds}`;
        if(fromNow.days >= 1) return `${fromNow.days + 1} ${this.data.app.$store.state.translatables.translation.datetime.days}`;
        if(fromNow.hours >= 1) return `${fromNow.hours + 1} ${this.data.app.$store.state.translatables.translation.datetime.hours}`;
        if(fromNow.minutes >= 1) return `${fromNow.minutes + 1} ${this.data.app.$store.state.translatables.translation.datetime.minutes}`;

        return `${fromNow.seconds} ${this.data.app.$store.state.translatables.translation.datetime.seconds}`;
    }

}
