<template>
    <div :class="{ 'is-seb': isSEB }">
        <layout
            :status="status"
            :session="session"
            :help="help"
            :loading="loading"
            @asked-for-help="askForHelp"
            @cancel-help="cancelHelp"
        >
            <template v-slot:title>
                <div
                    class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center"
                >
                    <h1
                        v-if="status !== 'active' && status !== 'outro'"
                        class="text-lg sm:text-3xl sm:leading-9 font-bold text-white"
                    >
                        {{ assessment.title }}
                    </h1>
                </div>
            </template>
            <template v-slot:contents>
                <div v-if="session && translation">
                    <div v-show="session.paused" class="relative">
                        <div
                            class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8"
                        >
                            <div
                                class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body"
                            >
                                <div class="bg-indigo-700 rounded-md">
                                    <div
                                        class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8"
                                    >
                                        <h2
                                            class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10"
                                        >
                                            <span class="block">{{
                                                translation.headings
                                                    .assessment_paused
                                            }}</span>
                                        </h2>
                                        <p
                                            class="mt-4 text-lg leading-6 text-indigo-200"
                                        >
                                            {{
                                                translation.messages
                                                    .paused_assessment
                                            }}
                                        </p>
                                        <span
                                            v-if="!session.ended_at"
                                            class="inline-flex rounded-md shadow-sm ml-auto mt-4"
                                        >
                                            <button
                                                :disabled="help"
                                                @click.prevent="askForHelp()"
                                                type="button"
                                                class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-100 focus:outline-none focus:border-bray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150 focus:ring-2 focus:ring-indigo-300"
                                            >
                                                <svg
                                                    v-if="help"
                                                    class="-ml-1 mr-2 h-5 w-5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
                                                    />
                                                </svg>
                                                <svg
                                                    v-else
                                                    class="-ml-1 mr-2 h-5 w-5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                                    />
                                                </svg>
                                                <span v-if="help"
                                                    >{{
                                                        translation
                                                            .buttons_links
                                                            .please_wait
                                                    }}&mldr;</span
                                                >
                                                <span v-else>{{
                                                    translation.buttons_links
                                                        .ask_for_help
                                                }}</span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="
                            !session.paused &&
                            (status === 'active' ||
                                status === 'waiting' ||
                                status === 'outro')
                        "
                        class="relative mt-8"
                    >
                        <div
                            v-if="!shouldLoadLearnosity"
                            class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8"
                        >
                            <div
                                class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body"
                            >
                                <div class="bg-indigo-700 rounded-md">
                                    <div
                                        class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8"
                                    >
                                        <h2
                                            class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10"
                                        >
                                            <span class="block">{{
                                                translation.headings
                                                    .data_out_of_sync
                                            }}</span>
                                        </h2>
                                        <p
                                            class="mt-4 text-lg leading-6 text-indigo-200"
                                        >
                                            {{
                                                translation.messages
                                                    .data_out_of_sync
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else-if="
                                !startByInvigilator ||
                                (startByInvigilator && startedByInvigilator)
                            "
                        >
                            <div v-if="LearnosityLoaded">
                                <div
                                    class="custom-hidden custom-md-inline-block m-auto absolute top-1 right-10"
                                >
                                    <AssessmentTimer
                                        v-if="status !== 'outro'"
                                        :class="
                                            [timer_alert ? 'bg-red-100' : ''] +
                                            rtl(' left-0') +
                                            ltr(' right-0')
                                        "
                                        class="local-timer flex items-center mt-2 mr-4 px-1 py-0.5 rounded-md"
                                        id="local-timer"
                                        :timer_alert="timer_alert"
                                        :if_paused_recently="pausedRecently"
                                        :time_remaining="time_remaining"
                                        :time="time"
                                        :time_remaining_announcement="
                                            time_remaining_announcement
                                        "
                                    />
                                </div>
                            </div>
                            <div class="pb-12 px-4 sm:px-6 lg:px-8 mr-4">
                                <div
                                    class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body"
                                >
                                    <div class="relative">
                                        <AssessmentTimer
                                            v-if="status !== 'outro'"
                                            :class="
                                                [
                                                    timer_alert
                                                        ? 'bg-red-100'
                                                        : '',
                                                ] +
                                                rtl(' left-0') +
                                                ltr(' right-0')
                                            "
                                            class="local-timer flex items-center absolute mt-3.5 mr-5 px-1 py-0.5 rounded-md md:hidden"
                                            id="local-timer"
                                            :timer_alert="timer_alert"
                                            :if_paused_recently="pausedRecently"
                                            :time_remaining="time_remaining"
                                            :time="time"
                                            :time_remaining_announcement="
                                                time_remaining_announcement
                                            "
                                        />
                                        <div v-if="LearnosityLoaded">
                                            <Teleport
                                                to=".app-layout .lrn-start-page .content"
                                            >
                                                <div
                                                    class="absolute top-1 right-0"
                                                >
                                                    <button
                                                        type="button"
                                                        @click.prevent="
                                                            showAccessibilityPopup()
                                                        "
                                                        class="lrn_btn custom-accessibility-btn"
                                                        title="Accessibility"
                                                    >
                                                        Accessibility
                                                    </button>
                                                </div>
                                            </Teleport>
                                        </div>
                                    </div>
                                    <div
                                        class="h-64 relative z-0"
                                        id="learnosity_assess"
                                    >
                                        <div
                                            class="absolute inset-0 flex items-center justify-center"
                                        >
                                            <div class="text-center">
                                                <span class="text-lg">{{
                                                    translation.messages
                                                        .loading_assessment_player
                                                }}</span>
                                                <loader
                                                    class="w-6 h-6 text-blue-500 mx-auto mt-2"
                                                ></loader>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div
                                class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8"
                            >
                                <div
                                    class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body"
                                >
                                    <div class="bg-indigo-700 rounded-md">
                                        <div
                                            class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8"
                                        >
                                            <h2
                                                class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10"
                                            >
                                                <span class="block">{{
                                                    translation.headings
                                                        .waiting_on_invigilator
                                                }}</span>
                                            </h2>
                                            <p
                                                class="mt-4 text-lg leading-6 text-indigo-200"
                                            >
                                                {{
                                                    translation.messages
                                                        .waiting_on_invigilator
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="!session.paused && status === 'submitted'">
                        <div
                            v-if="!proctored"
                            class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8"
                        >
                            <div
                                class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body"
                            >
                                <div class="bg-indigo-700 rounded-md">
                                    <div
                                        class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8"
                                    >
                                        <h2
                                            class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10"
                                        >
                                            <span class="block">{{
                                                config.submitted_message_heading
                                                    ? config.submitted_message_heading
                                                    : translation.headings
                                                            .assessment_submitted
                                            }}</span>
                                        </h2>
                                        <p
                                            class="mt-4 text-lg leading-6 text-indigo-200"
                                        >
                                            {{
                                                config.submitted_message_text
                                                    ? config.submitted_message_text
                                                    : translation.messages
                                                            .assessment_submitted
                                            }}
                                        </p>
                                        <a
                                            @click.prevent="submittedCTA()"
                                            test-ref="back-to-assessments"
                                            href="#"
                                            class="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 hover:bg-indigo-50 transition duration-150 ease-in-out sm:w-auto"
                                        >
                                            {{
                                                config.submitted_message_button
                                                    ? config.submitted_message_button
                                                    : translation.buttons_links
                                                          .back_to_assessments
                                            }}
                                        </a>
                                        <a
                                            v-if="isSEB"
                                            href="/quit-seb"
                                            class="ml-8 mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 hover:bg-indigo-50 transition duration-150 ease-in-out sm:w-auto"
                                        >
                                            {{
                                                translation.buttons_links
                                                    .close_seb
                                            }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8"
                        >
                            <div
                                class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body"
                            >
                                <div class="bg-indigo-700 rounded-md">
                                    <div
                                        class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8"
                                    >
                                        <h2
                                            class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10"
                                        >
                                            <span class="block">
                                                {{
                                                    translation.headings
                                                        .proctored_assessment_submitted
                                                }}
                                            </span>
                                        </h2>
                                        <p
                                            class="mt-4 text-lg leading-6 text-indigo-200"
                                        >
                                            {{
                                                translation.messages
                                                    .proctored_assessment_submitted
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="!session.paused && status === 'submit_failed'">
                        <div
                            class="max-w-5xl mx-auto pb-12 px-4 sm:px-6 lg:px-8"
                        >
                            <div
                                class="bg-white rounded-lg border-l border-r border-gray-50 px-2 py-2 sm:py-6 sm:px-6 min-h-body"
                            >
                                <div class="bg-indigo-700 rounded-md">
                                    <div
                                        class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8"
                                    >
                                        <h2
                                            class="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10"
                                        >
                                            <span class="block">
                                                Your time is now up but we
                                                failed to submit your
                                                assessment!
                                            </span>
                                        </h2>
                                        <span
                                            class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-800 mt-4"
                                        >
                                            {{ session.id }}
                                        </span>
                                        <p
                                            class="mt-4 text-lg leading-6 text-indigo-200"
                                        >
                                            {{
                                                translation.messages
                                                    .submit_failed
                                            }}
                                        </p>
                                        <a
                                            @click.prevent="
                                                $router.push({ name: 'home' })
                                            "
                                            href="#"
                                            class="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 hover:bg-indigo-50 transition duration-150 ease-in-out sm:w-auto"
                                        >
                                            {{
                                                translation.buttons_links
                                                    .back_to_assessments
                                            }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
import Layout from "@/components/AssessmentLayout.vue";
import Loader from "@/components/Loader.vue";
import MethodsBus from "@/utils/methods-bus.js";
import RegisterListeners from "@/utils/register-listeners.js";
import {
    learnosityAccessibilityPalettes,
    learnosityEvents,
    getAllColorPalletsOptions,
} from "@/utils/learnosity.js";
import AssessmentTimer from "./AssessmentTimer.vue";
import {resolveTransitionHooks} from "vue";

window.LearnosityLoaded = false;

export default {
    beforeRouteEnter(to, from, next) {
        window.bus.$gates.BeforeEnterAssessment(to, from, next);
    },
    props: {
        proctored: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        Layout,
        Loader,
        AssessmentTimer,
    },
    data() {
        return {
            LearnosityLoaded: false,
            time_remaining: "",
            time_remaining_announcement: "",
            timer_alert: false,
            help: false,
            loading: false,
            status: "active",
            pausedRecently: false,
            saver: null,
        };
    },
    mounted() {
        this.setConfig();

        this.$saves.checkLocalSaves();
        if (!LearnosityLoaded && this.session.custom_reading_time) {
            this.$global.success(
                "The reading time is updated to " +
                    this.session.custom_reading_time +
                    " minutes.",
            );
        }

        if (this.session.group.debug) {
            this.$debug.enable();
        }

        if (this.session.paused) {
            this.pausedRecently = true;
        }

        this.registerSocketChannels(() => {
            this.listenToSocketChannels();
        });

        if (
            this.shouldLoadLearnosity &&
            (!this.startByInvigilator ||
                (this.startByInvigilator && this.startedByInvigilator))
        ) {
            this.loadLearnosity();
        }

        if (this.startByInvigilator && !this.startedByInvigilator) {
            this.status = "waiting";
        }

        this.startTimer();
        this.startSaverInterval();

        /** Read-speaker double click override changes **/
        document.addEventListener('dblclick', function(event) {
            const popup = document.getElementById('rsbtn_popup');
            if (popup) {
                let currentTop = parseInt(popup.style.top,10);
                currentTop += 60;
                popup.style.top = currentTop + 'px';
            }
        });

        /**
         * When an assessment "is_practise" save and submit are disabled via the api
         * which means we can't tap into the callbacks to notify the server of timestamp updates.
         * So we'll do a little workaround to manually do it.
         */
        if (this.schedule.is_practise) {
            window.addEventListener("mouseup", this.mouseUp);
        }
        window.addEventListener("beforeunload", this.beforeUnLoad);

    },
    beforeUnmount() {
        clearInterval(this.saver);
        window.removeEventListener("beforeunload", this.beforeUnLoad);
    },
    computed: {
        state() {
            return this.$store.state.state;
        },
        config() {
            return this.$store.state.player_config;
        },
        translation() {
            return this.$store.state.translatables.translation;
        },
        session() {
            return this.$store.state.assessment;
        },
        assessment() {
            return this.$store.state.assessment.version.assessment;
        },
        assessmentVersion() {
            return this.$store.state.assessment.version;
        },
        schedule() {
            return this.session.group.assessment_schedule;
        },
        candidate() {
            return this.$store.state.user;
        },
        startByInvigilator() {
            return this.schedule.assessment_start_type === "invigilator";
        },
        startedByInvigilator() {
            return this.session.group.started_by_invigilator_at !== null;
        },
        time() {
            return (
                String(this.session.max_time.h).padStart(2, 0) +
                ":" +
                String(this.session.max_time.i).padStart(2, 0) +
                ":" +
                String(this.session.max_time.s).padStart(2, 0)
            );
        },
        blobs() {
            return this.$store.state.assessment_blob;
        },
        isSEB() {
            return this.$global.isSafeExamBrowser();
        },
        shouldLoadLearnosity() {
            return this.$store.state.should_load_learnosity;
        },
    },
    methods: {
        ...MethodsBus,
        ...RegisterListeners,
        async setCandidateAccessibility(inst) {
            this.loading = true;
            this.$axios
                .get(endpoint(`/api/candidates/accessability`))
                .then((res) => {
                    inst.api
                        .assessApp()
                        .setAccessibility(res.data.accessibility_settings);
                })
                .finally(() => {
                    this.loading = false;
                    // To avoid text speaker to read multiple times MCQ options
                    document
                        .querySelectorAll(".lrn-mcq-option")
                        .forEach(function (option) {
                            // Find the child element with the class 'lrn_contentWrapper'
                            var contentWrapper = option.querySelector(
                                ".lrn_contentWrapper",
                            );
                            if (contentWrapper) {
                                // Find the next sibling div and set its content to empty
                                var nextDiv = contentWrapper.nextElementSibling;
                                if (
                                    nextDiv &&
                                    nextDiv.tagName.toLowerCase() === "div"
                                ) {
                                    nextDiv.innerHTML = "";
                                }
                            }
                        });
                });
        },
        goToItem(itemReference) {
            this.api.items().goto(itemReference);
        },
        setInterLinkingOptions() {
            const itemsContainer = document.querySelector(
                ".slides-container .slides-control",
            );
            const interlinkingOptions = JSON.parse(
                this.assessmentVersion.interlinking_options,
            );

            for (const key in interlinkingOptions) {
                if (!interlinkingOptions.hasOwnProperty(key)) continue;

                const item = itemsContainer.querySelector(
                    `[data-reference='${key}']`,
                );
                if (!item) continue;

                const itemQuestions = item.querySelectorAll(
                    `[data-lrn-widget-type='question']`,
                );
                const itemQuestionsHasInterlinking = interlinkingOptions[key];

                for (const ele in itemQuestionsHasInterlinking) {
                    if (!itemQuestionsHasInterlinking.hasOwnProperty(ele)) {
                        continue;
                    }

                    const question = itemQuestions[ele - 1];
                    if (!question) continue;

                    const interlinkingTags = question.querySelectorAll(
                        "[data-interlinking-options]",
                    );
                    const links = itemQuestionsHasInterlinking[ele];

                    interlinkingTags.forEach((tag, index) => {
                        tag.addEventListener("click", (e) => {
                            e.preventDefault();
                            this.goToItem(links[index]);
                        });
                    });
                }
            }
        },
        eventsWrapperRunWhenItemLoad() {
            this.closeAllAccordions();
            this.disableEditorLinksIfSEB();
            const buttons = document.querySelectorAll("button");
            if (buttons) {
                buttons.forEach((button) => {
                    button.setAttribute("tabindex", "0");
                });
            }
        },
        disableEditorLinksIfSEB() {
            const editorInSEBMode = document.querySelector(
                ".is-seb .lrn_texteditor_editable",
            );

            if (editorInSEBMode) {
                let liveLinks = ".is-seb .lrn_texteditor a";

                // prevent if SEB initially
                this.disableLiveLinks(liveLinks);

                // disable all links inside the editor when blur
                editorInSEBMode.addEventListener("blur", () => {
                    this.disableLiveLinks(liveLinks);
                });
                // handle when paste link in the editor
                editorInSEBMode.addEventListener("paste", () => {
                    setTimeout(() => {
                        this.disableLiveLinks(liveLinks);
                    }, 0);
                });
            }
        },
        disableLiveLinks(selector) {
            const links = document.querySelectorAll(selector);
            links.forEach((aTag) => {
                aTag.href = "#";
                aTag.classList.add("dead-link");
                aTag.addEventListener("click", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                });
            });
        },
        closeAllAccordions() {
            const details = document.querySelectorAll("details");
            details.forEach((el) => {
                el.open = false;
            });
        },
        startSaverInterval() {
            this.saver = setInterval(() => {
                this.saveLearnosity();
            }, 7000);
        },
        submittedCTA() {
            if (this.config.submitted_message_redirect_url) {
                window.location = this.config.submitted_message_redirect_url;
            } else {
                this.$router.push({ name: "home" });
            }
        },
        setConfig() {
            this.$store.commit("setPlayerConfig", this.session.player_config);
        },
        beforeUnLoad() {
            this.$debug.log("Status: offline");
            this.setStatus("offline");
        },
        blur() {
            this.$debug.log("Status: offline");
            this.setStatus("offline");
        },
        focus() {
            this.$debug.log("Status: online");
            this.setStatus("online");
        },
        mouseUp(e) {
            if (
                e.target.classList.value ===
                "test-dialog-save-submit lrn_btn_blue"
            ) {
                this.inform("delegate:test:submit:success", {});
            }
        },
        rtl(classes) {
            return this.session.version.direction === "rtl" ? classes : "";
        },
        ltr(classes) {
            return this.session.version.direction === "ltr" ? classes : "";
        },
        startTimer() {
            this.$global.ticker("assessment:timer", (_seconds, remove) => {
                if (
                    !window.LearnosityLoaded ||
                    !this.session.ends_at ||
                    this.session.paused
                ) {
                    return;
                }
                this.$global.timeUntil(this.session.timer_ends_at, (time) => {
                    if (
                        time.completed &&
                        !this.session.player_config.time_lapse
                    ) {
                        if (!this.session.submitted_at) {
                            this.submitAssessment();
                        }
                        this.time_remaining = "00:00";
                        this.time_remaining_announcement = "00:00:00";
                        return remove();
                    }
                    let time_remaining = "";

                    if (String(time.hours).includes("-")) {
                        let mins = String(Number(time.minutes) + 1).replace(
                            "-",
                            "",
                        );
                        let secs = String(time.seconds).replace("-", "");

                        if (secs === "60") {
                            mins = String(Number(mins) + 1);
                            secs = "00";
                        }

                        time_remaining +=
                            mins.padStart(2, 0) + ":" + secs.padStart(2, 0);

                        this.timer_alert = true;
                    } else {
                        if (time.hours) {
                            time_remaining += String(time.hours) + ":";
                        }
                        time_remaining +=
                            String(time.minutes).padStart(2, 0) +
                            ":" +
                            String(time.seconds).padStart(2, 0);
                    }

                    if (time.seconds === 0 && this.shouldLoadLearnosity) {
                        this.saveLocal();
                    }

                    this.time_remaining = time_remaining;
                    if (
                        (time.minutes % 30 === 0 && time.seconds === 0) ||
                        (time.hours === 0 &&
                            time.minutes === 15 &&
                            time.seconds === 0)
                    ) {
                        this.time_remaining_announcement = this.time_remaining;
                        if (!time.hours) {
                            this.time_remaining_announcement =
                                "00:" + this.time_remaining_announcement;
                        }
                    }
                    if (
                        parseInt(time.hours) === 0 &&
                        parseInt(time.minutes) < this.schedule.warning_time
                    ) {
                        this.timer_alert = true;
                    }

                    if (
                        this.session.session_activity.config.configuration
                            ?.reading_mode?.reading_time
                    ) {
                        let now = new Date().getTime();
                        let started_at = new Date(
                            this.session.started_at,
                        ).getTime();
                        let passed_time = now - started_at;

                        if (
                            Math.trunc(passed_time / 1000) <
                            this.session.session_activity.config.configuration
                                .reading_mode.reading_time
                        ) {
                            let reading_time_remaining =
                                started_at +
                                this.session.session_activity.config
                                    .configuration.reading_mode.reading_time *
                                    1000 -
                                now;
                            time_remaining =
                                "Reading Time: " +
                                String(
                                    Math.floor(
                                        (reading_time_remaining %
                                            (1000 * 60 * 60 * 24)) /
                                            (1000 * 60 * 60),
                                    ),
                                ) +
                                ":" +
                                String(
                                    Math.floor(
                                        (reading_time_remaining %
                                            (1000 * 60 * 60)) /
                                            (1000 * 60),
                                    ),
                                ).padStart(2, 0) +
                                ":" +
                                String(
                                    Math.floor(
                                        (reading_time_remaining % (1000 * 60)) /
                                            1000,
                                    ),
                                ).padStart(2, 0);
                            this.time_remaining = time_remaining;
                        }
                    }
                });
            });
        },
        registerSocketChannels(subscribed) {
            this.$debug.log(
                `Registering public group App.AssessmentScheduleGroup.${this.session.group.id}`,
            );
            this.$global.registerCandidateChannel();
            this.$global.registerChannel(
                "public-group",
                `App.AssessmentScheduleGroup.${this.session.group.id}`,
                "presence",
                subscribed,
            );
        },
        setStatus(status) {
            this.$store.commit("setStatus", status);
            this.inform("candidate:focus", { status });
        },
        askForHelp() {
            this.updateHelpStatus(1);
        },
        showAccessibilityPopup() {
            if (window.LearnosityLoaded) {
                this.api
                    .assessApp()
                    .addAccessibilityPalettes(getAllColorPalletsOptions());
                this.api.dialogs().accessibility.show();
                const accessibilityPanel = document.querySelector(
                    ".accessibility-panel",
                ).parentElement;
                accessibilityPanel.style.zIndex = 10000;
            }
        },
        cancelHelp() {
            this.updateHelpStatus(0);
        },
        updateHelpStatus(helpStatus) {
            this.loading = true;
            this.help = helpStatus === 1;

            this.$debug.log("Triggering server side flag for help request");
            this.$axios
                .post(
                    endpoint(
                        `/api/candidates/schedule-group/${this.session.group.id}/events/help-request`,
                    ),
                    {
                        session_id: this.session.id,
                        help_status: helpStatus,
                    },
                )
                .then(() => {
                    this.loading = false;
                })
                .catch((error) => this.$global.globalAXIOSErrorHandler(error));
            this.$global.channel("public-group", (socket) => {
                if (helpStatus === 1) {
                    this.$debug.log("Triggering candidate help request");
                    socket.whisper("candidate:help", {
                        session_id: this.session.id,
                        forename: this.candidate.forename,
                        surname: this.candidate.surname,
                    });
                } else {
                    this.$debug.log("Triggering candidate cancel help request");
                    socket.whisper("candidate:cancel-help", {
                        session_id: this.session.id,
                    });
                }
            });
        },
        addHoverDescOnPlayButton() {
            const rsPlayButtons = document.querySelectorAll('.rsbtn_play');
            if(rsPlayButtons.length > 0){
                rsPlayButtons.forEach(button => {
                    button.setAttribute('title', 'Play');
                });
            }
        },
        handleHelpButtonClick(){
            const lastButtons = document.querySelectorAll('.rsbtn_tooltoggle');
            if(lastButtons.length > 0){
                lastButtons.forEach(button => {
                    button.addEventListener('click', () => {
                        const helpButtons = document.querySelectorAll('button[title="Help"]');
                        if (helpButtons.length) {
                            helpButtons.forEach((helpButton)=>{
                                helpButton.addEventListener('click', this.getModalDetails);
                            })
                        }
                    });
                });
            }
        },
        getModalDetails() {
            const timeoutDuration = 5000;
            const intervalDuration = 50;
            let elapsedTime = 0;

            const intervalId = setInterval(() => {
                const modal = document.querySelector(".rshelp-container");
                if(modal){
                    this.insertNoteOnModal(modal);
                    clearInterval(intervalId);
                }

                elapsedTime += intervalDuration;
                if (elapsedTime >= timeoutDuration) {
                    clearInterval(intervalId);
                }
            }, intervalDuration);
        },
        insertNoteOnModal(modal) {
            const p = document.createElement('p');
            p.textContent = 'Please note: the features mentioned in the menu are not available with these assessments because they are not allowed / do not work at this time.';
            p.classList.add('disclaimer');
            modal.insertBefore(p, modal.firstChild);
        },
        loadLearnosity() {
            if (this.shouldLoadLearnosity === false) {
                window.LearnosityItems = undefined;
                this.$store.commit("setShouldLoadLearnosity", true);
            } else if (typeof window.LearnosityItems !== "undefined") {
                this.$debug.log("Initiating Learnosity API");
                this.api = LearnosityItems.init(
                    this.session.learnosity_data.options,
                    {
                        readyListener: () => {
                            learnosityEvents(
                                this,
                                this.eventsWrapperRunWhenItemLoad,
                            );
                            learnosityAccessibilityPalettes(this);
                            window.LearnosityLoaded = true;
                            this.$nextTick(() => {
                                this.LearnosityLoaded = true;
                                this.eventsWrapperRunWhenItemLoad();
                                if (
                                    this.assessmentVersion.interlinking_options
                                ) {
                                    this.setInterLinkingOptions();
                                }
                                if (
                                    this.assessment
                                        .assessment_config_template_id
                                ) {
                                    this.setCandidateAccessibility(this);
                                }
                            });

                            this.session.session_activity =
                                this.api.getActivity();
                            if (this.session.with_reader) {
                                if (
                                    !document.getElementById(
                                        "readspeaker_Learnosity0",
                                    )
                                ) {
                                    this.$debug.log(
                                        "ReadSpeaker UI not found, initializing...",
                                    );
                                    window.rsCallbacks;
                                    ReadSpeaker.q(() => {
                                        ReadSpeaker.Learnosity.init();
                                        window.ReadSpeakerInitiated = true;
                                    });
                                    this.handleHelpButtonClick();
                                    this.addHoverDescOnPlayButton();
                                } else {
                                    this.$debug.log(
                                        "ReadSpeaker already exists, skipping initialization...",
                                    );
                                }
                            }
                            if (
                                this.session.version.assessment.calculator ===
                                "scientific"
                            ) {
                                this.scrollCalcInputToRight();
                            }
                        },
                    },
                );
            }
        },
        scrollCalcInputToRight() {
            document
                .querySelector(".lrn_float_element_container")
                .classList.add("lrn-calc-scientific");
            let input = document.querySelector(
                ".lrn-calc-scientific input.lrn_calc_result",
            );
            let width;
            let calcWidth = function () {
                return Math.floor(input.getBoundingClientRect().width);
            };
            document
                .querySelector(".lrn-calc-scientific")
                .addEventListener("keyup", function () {
                    if (!width) {
                        width = calcWidth();
                    }
                    input.scrollTo(width, 0);
                });
            document
                .querySelector(".lrn-calc-scientific")
                .addEventListener("click", function (event) {
                    if (event.target.tagName !== "BUTTON") {
                        return;
                    }
                    if (!width) {
                        width = calcWidth();
                    }
                    input.scrollTo(width, 0);
                });
        },
        resume() {
            this.$debug.log("Learnosity API: Enable session");
            this.api.questionsApp().enable();
        },
        pause() {
            this.$debug.log("Learnosity API: Pause session");
            this.api.questionsApp().disable();
            this.api.dialogs().pause.show();
        },
        saveLocal(status) {
            if (!this.shouldLoadLearnosity) {
                return;
            }
            status = status || "local-save";
            let data = {
                group_id: this.session.group.id,
                session_id: this.session.id,
                blob: this.api.assessApp().getSubmissionData(),
                status: status,
                is_practice: this.schedule.is_practise,
                save_time: new Date().toISOString(),
                ends_at: this.session.ends_at,
                dispatched: this.$store.state.state === "connected",
                user_id: this.$store.state.user.id,
                title: this.assessment.title,
            };
            this.$store.commit("setAssessmentBlob", data);
            this.$debug.log("Local save completed", data);
        },
        saveLearnosity() {
            if (!this.shouldLoadLearnosity) {
                return;
            }
            if (!this.schedule.is_practise) {
                this.$debug.log("Learnosity API: Saving session");
                this.api.assessApp().save();
            }
        },
        save(status) {
            if (!this.shouldLoadLearnosity) {
                return;
            }
            this.saveLearnosity();
            this.saveLocal(status);
            this.$debug.log("Triggering blob save to Modulus");
            if (this.status === "connected") {
                this.$axios
                    .post(
                        endpoint(
                            `/api/candidates/schedule-group/${this.session.group.id}/events/save-blob`,
                        ),
                        {
                            session_id: this.session.id,
                            blob: this.api.assessApp().getSubmissionData(),
                        },
                    )
                    .catch((error) =>
                        this.$global.globalAXIOSErrorHandler(error),
                    );
            }
            this.exitFullScreenOnSubmitSuccess();
        },
        submitAssessment() {
            this.session.submitted_at = new Date().toUTCString();
            this.save();
            if (!this.schedule.is_practise) {
                this.$debug.log("Learnosity API: Submitting session");
                this.api.assessApp().submit({
                    success: () => {
                        this.status = "submitted";
                    },
                    error: () => {
                        this.status = "submit_failed";
                    },
                });
            }
            this.exitFullScreenOnSubmitSuccess();
        },
        inform(event, payload) {
            if (this.session) {
                payload.session_id = this.session.id;
                this.$global.channel("public-group", (socket) => {
                    socket.whisper(event, payload);
                });
            }
        },
        exitFullScreenOnSubmitSuccess() {
            if (
                this.status === "submitted" &&
                document.fullscreenElement !== null
            ) {
                let isSafari = /^((?!chrome|android).)*safari/i.test(
                    navigator.userAgent,
                );
                if (isSafari) {
                    typeof document.webkitExitFullscreen !== "undefined" &&
                        document.webkitExitFullscreen();
                } else {
                    typeof document.exitFullscreen !== "undefined" &&
                        document.exitFullscreen();
                }
            }
        },
    },
};

// document.addEventListener('dblclick', function(event) {
//     const popup = document.getElementById('rsbtn_popup');
//     if (popup) {
//         let currentTop = parseInt(popup.style.top,10);
//         currentTop += 60;
//         popup.style.top = currentTop + 'px';
//
//     }
// });

</script>

<style lang="scss">
.lrn-assess .timer {
    visibility: hidden;
}
.lrn_widget .lrn_selected {
    background-color: #eaeaea !important;
}

.local-timer {
    font-variant-numeric: tabular-nums;
    z-index: 999999999;
}

.lrn.lrn-assess
    .lrn-right-region
    .right-wrapper.menu-open
    button.lrn_btn
    > span.btn-label {
    position: initial !important;
    display: inline !important;
    padding-left: 10px !important;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper.menu-open .menu-mask {
    width: 175px !important;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper.menu-open {
    width: fit-content !important;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper.menu-open .menu-overlay {
    width: 100% !important;
}

.lrn.lrn-assess
    .lrn-right-region
    .right-wrapper.menu-open
    .menu-mask
    .menu-buttons {
    display: flex;
    flex-direction: column;
    position: relative;
}

.lrn_btn.test-answer-masking.pos-middle {
    display: none;
}

.lrn.lrn-assess
    .lrn-right-region
    .right-wrapper
    .lrn_btn
    .lrn_svg_icon_notepad {
    top: -1px;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper .lrn_btn .lrn_svg_icon {
    margin-top: 0 !important;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper:not(.menu-open) .lrn_btn .lrn_svg_icon {
    left: 0px !important;
    text-align: center;
    width: 100%;
}
.lrn.lrn-assess .lrn-right-region.lrn-region {
    height: auto !important;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper .menu-mask {
    height: auto !important;
    background-color: #eaeaea;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper .menu-overlay {
    height: auto !important;
    position: relative !important;
    float: right;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper .lrn_btn.menu-expand-button {
    position: relative !important;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper {
    width: auto !important;
    background-color: transparent !important;
}

.lrn.lrn-assess .lrn-vertical-toc {
    position: relative !important;
    height: auto !important;
    right: auto !important;
}

.lrn.lrn-assess .right-wrapper.menu-open .lrn-vertical-toc {
    position: absolute !important;
    right: 50px !important;
}

.lrn.lrn-assess .lrn-vertical-toc .slides-vertical-pagination {
    max-height: none !important;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper.menu-with-scroll::after {
    display: none;
}

.lrn.lrn-assess
    .lrn-right-region
    .right-wrapper:not(.menu-open)
    .menu-mask::after {
    background-image: linear-gradient(
        0deg,
        #cccccc,
        rgba(204, 204, 204, 0) 70%
    );
    content: "";
    height: 20px;
    right: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    width: 50px;
    z-index: 3;
}

.custom-hidden {
    display: none !important;
}

@media (min-width: 768px) {
    .custom-md-inline-block {
        display: inline-block !important;
    }
}

.lrn-fullscreen .custom-hidden {
    display: inline-block !important;
}

/* Start Accordion */
.lrn.lrn-assess {
    // Mixin to reset colors
    %resetColors {
        color: unset !important;
        border-color: unset !important;
        background: unset;
    }

    div[data-lrn-widget-container] {
        padding: 6px !important;

        > div {
            padding: 6px !important;
        }
    }

    details {
        @extend %resetColors;

        * {
            @extend %resetColors;
        }
    }

    details summary {
        cursor: pointer;
        display: flex !important;
        align-items: center;
        gap: 5px;
        padding: 0px 12px;
        overflow: hidden;

        & > * {
            display: inline;
            line-height: 1.4 !important;
        }

        &.accordion-2 {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin: 10px 0;
            }
        }

        &::-webkit-details-marker {
            display: none;
            content: "";
        }

        &::before {
            content: "›";
            font-weight: bold;
            font-size: 2em;
            display: inline-block;
            transform: rotate(0deg);
            transition: transform 0.2s ease;
            line-height: normal;
        }
    }

    details[open] {
        > summary {
            &::before {
                transform: rotate(90deg) translate(1px) translateY(-3px);
            }
        }
    }
}

/* End Accordion */

/* Start Focus Indicator */
.lrn.lrn-assess {
    // The new Focus indicator settings to be applied consistently to to all interactive elements in the authoring tool
    // Color Variables
    $blue: #156e8a;
    $yellow: #ffcb52;
    $white: #fff;

    *:focus,
    .lrn_focused,
    .lrn_focus {
        box-shadow: 0px 0px 2px 0px grey !important;
        border: 3px solid $blue !important;
        outline-offset: 2px !important;
    }

    .form-control,
    input,
    select {
        &:focus {
            border: none !important;
        }
    }

    .lrn_textinput.lrn_focus {
        outline: none !important;
    }

    .lds-btn-check:focus + .lds-btn-secondary::after,
    .lrn .lds-btn-check:focus + .lrn_btn:is(.lds-btn-secondary)::after,
    .lds-btn-secondary:focus::after,
    .lrn .lrn_btn:focus:is(.lds-btn-secondary)::after {
        border: none !important;
    }

    .bootstrap-select .dropdown-toggle:focus,
    .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
        outline: 3px solid $white !important;
        outline-offset: 3px !important;
    }

    .table {
        color: inherit !important;
    }

    .lrn_btn_sort:focus,
    .lrn_arrow:focus,
    .lrn_btn:not(.lrn-assess-close-review-screen-btn):focus,
    .lrn-shading-cell:focus,
    .lrn-gridded-input:focus {
        position: relative;
        z-index: 2;
    }

    .lrn_widget.lrn_mcq .lrn_mcqgroup.lrn_mcqgroup-vertical .lrn-mcq-option {
        border-left: none !important;
    }

    .items-grid {
        padding: 6px;
    }

    .right-wrapper.menu-open {
        .menu-mask {
            button {
                &::before {
                    text-align: center;
                    width: auto !important;
                    color: inherit;
                }
            }
        }
    }

    .menu-mask {
        button {
            &::before {
                text-align: center;
                width: 100%;
            }
        }
    }

    .lrn-region:not(.lrn-items-region) .lrn_btn {
        padding: 0.4em 0.55em !important;
    }

    .lrn-right-region .right-wrapper .lrn_btn.menu-expand-button:before {
        right: -8px !important;
        margin-top: 2px !important;
    }

    .lrn-right-region
        .right-wrapper.menu-open
        .lrn_btn.menu-expand-button:before {
        right: 0px !important;
    }

    .lrn-mcq-option.lrn_selected {
        position: relative;
        z-index: 1;
        background-color: #eaeaea !important;
    }

    .lrn-input[type="radio"] {
        &:focus {
            outline: none !important;
            box-shadow: none !important;

            + .lrn-label {
                box-shadow: none !important;
                outline: 3px solid $white !important;
                outline-offset: 3px;
            }
        }
    }

    // light mode

    .lrn_focused {
        border-color: $white !important;
        outline-color: $white !important;
    }

    .lrn_widget.lrn_chemistryessayV2 .lrn-wysihtml-toolbar:focus-within,
    .lrn_widget.lrn_formulaessayV2 .lrn-wysihtml-toolbar:focus-within,
    .lrn_widget.lrn_longtextV2 .lrn-wysihtml-toolbar:focus-within {
        outline-color: $white !important;
    }

    .lrn_widget.lrn_simpleshading
        .lrn-shading-wrapper.lrn_no_touch
        .lrn-shading-cell:focus {
        outline-color: $white !important;
    }

    .lrn_tokenhighlight_highlight:focus,
    .lrn_token:focus,
    .lrn-shading-cell:focus {
        border-color: $white !important;
    }

    .lrn_widget.lrn_numberlineplot
        .lrn_jxg_toolbox
        li
        button:not(.lrn_disabled):hover,
    .lrn_widget.lrn_numberlineplot
        .lrn_jxg_toolbox
        li
        button:not(.lrn_disabled):focus {
        border-color: $white !important;
    }

    .lrn.lrn-assess .nav-tabs > li:focus-within {
        outline: none;
    }

    .lrn_widget.lrn_hotspot .lrn-polygons .lrn-selected polygon {
        stroke: $white;
    }

    .lrn_widget .lrn_dragdrop:not(.lrn_disabled).lrn_dropzone.lrn_highlight {
        border: none !important;
    }

    .lrn-shading-cell.lrn-shading-selected {
        border: none !important;
    }

    .lrn_widget.lrn_simpleshading
        .lrn-shading-wrapper.lrn_no_touch
        .lrn-shading-cell:focus {
        border: none !important;
        z-index: 12;
        position: relative;
    }

    .lrn.lrn-assess .nav-tabs > li.active > a {
        margin: 0;
    }

    .lrn_mcqgroup {
        padding: 6px !important;
    }

    .lrn input[type="file"]:focus,
    .lrn input[type="radio"]:focus,
    .lrn input[type="checkbox"]:focus {
        outline-offset: 3px !important;
    }

    // dark mode

    .access-contrast-6.lrn-assess.lrn.lrn-assess.access-contrast-6
        .lrn_response_wrapper
        .lds-btn::after {
        border-color: $white !important;
    }

    .menu-buttons {
        > button:focus {
            outline: none !important;
            border: 2px solid $white !important;
        }
    }

    &.access-contrast-3,
    &.access-contrast-6 {
        .lrn_widget.lrn_imageclozeassociationV2
            .lrn_response_input
            .lrn_dropzones_container
            .lrn_imagecloze_response
            .lrn_dropzone_mask.lrn_highlight {
            border-color: $yellow !important;
        }

        .lrn_widget.lrn_numberlineplot
            .lrn_jxg_toolbox
            li
            button:not(.lrn_disabled):hover,
        .lrn_widget.lrn_numberlineplot
            .lrn_jxg_toolbox
            li
            button:not(.lrn_disabled):focus {
            border-color: $yellow !important;
        }

        .lrn_response_wrapper .lds-btn::after {
            border-color: $yellow !important;
        }

        .menu-buttons {
            > button:focus {
                border: 2px solid $yellow !important;
            }
        }

        .lrn_focused {
            border-color: $yellow !important;
            outline-color: $yellow !important;
        }

        .lrn_widget.lrn_chemistryessayV2 .lrn-wysihtml-toolbar:focus-within,
        .lrn_widget.lrn_formulaessayV2 .lrn-wysihtml-toolbar:focus-within,
        .lrn_widget.lrn_longtextV2 .lrn-wysihtml-toolbar:focus-within {
            outline-color: $yellow !important;
        }

        .lrn_widget.lrn_simpleshading
            .lrn-shading-wrapper.lrn_no_touch
            .lrn-shading-cell:focus {
            outline-color: $yellow !important;
        }

        .lrn_tokenhighlight_highlight:focus,
        .lrn_token:focus,
        .lrn-shading-cell:focus {
            border-color: $yellow !important;
            color: $yellow !important;
        }

        *:focus,
        .lrn_focused,
        .lrn_focus {
            box-shadow: 0px 0px 2px 0px grey !important;
            outline: 3px solid $yellow !important;
            outline-offset: 2px;
        }

        .lrn-input[type="radio"] {
            &:focus {
                outline: none !important;
                box-shadow: none !important;

                + .lrn-label {
                    outline: 3px solid $yellow !important;
                    outline-offset: 3px;
                }
            }
        }

        .lrn_widget.lrn_hotspot .lrn-polygons .lrn-selected polygon {
            stroke: $yellow;
        }

        .lrn_textinput.lrn_focus {
            outline: none !important;
        }
    }

    .lrn_widget.lrn_imageclozeassociationV2
        .lrn_response_input
        .lrn_dropzones_container
        .lrn_imagecloze_response
        .lrn_response_container
        .lrn_btn_drag {
        margin-top: 7px !important;
        margin-left: 6px !important;
    }

    .lrn_widget.lrn_imageclozeassociationV2
        .lrn_response_input
        .lrn_dropzones_container
        .lrn_imagecloze_response {
        min-height: 44px !important;
    }

    .lrn-right-region .right-wrapper .menu-overlay:not(:first-of-type),
    .access-contrast-3.lrn-assess.lrn.lrn-assess
        .lrn-right-region
        .right-wrapper
        .menu-overlay:not(:last-of-type) {
        border: none !important;
    }

    .lrn-right-region .right-wrapper .lrn_btn:focus,
    .access-contrast-3.lrn-assess.lrn.lrn-assess
        .lrn-right-region
        .right-wrapper
        .lrn_btn.lrn-calc-toggle:focus,
    .access-contrast-3.lrn-assess.lrn.lrn-assess
        .lrn-right-region
        .right-wrapper
        .lrn_feature.lrn_widget:focus {
        border: none !important;
    }
}

.cke_editable,
.lrn-author-item-content,
.lrn-assess,
.lrn-question-preview {
    ul {
        list-style: disc !important;
    }

    ol {
        list-style: auto !important;
    }
}

.lrn {
    .table {
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    border: 1px solid #d9d9d9;
                }
            }
        }
    }
}

/* End Focus Indicator */

/* Change the unclickable links color */
.lrn_texteditor a.dead-link,
.lrn_texteditor a.dead-link:hover {
    color: #333;
}

.custom-accessibility-btn:before {
    font-family: "LearnosityIconsRegular";
    content: "a";
    font-size: 20px;
}

.access-contrast-Yellow-on-navy {
    .lrn_question .lrn_stimulus {
        color: #fff25d;
    }
}

.access-contrast-Yellow-on-navy {
    .lds-root {
        color: #fff25d;
    }
}

.access-contrast-White-on-black {
    .lds-root {
        color: #ffff !important;
    }
}

.access-contrast-Purple-on-light-green {
    .lds-root {
        color: #a9306f !important;
    }
}

.access-contrast-Yellow-on-navy.lrn-assess.lrn.lrn-assess .nav-tabs > .nav-tab {
    color: #fff25d !important;
}

.access-contrast-Yellow-on-navy.lrn-assess.lrn.lrn-assess .local-timer {
    color: #fff25d !important;
}

.lrn_tokenhighlight_hover {
    background-color: #ffffff !important;
}

.lrn_tokenhighlight_highlight {
    background-color: #ffffff !important;
}

.lrn pre {
    background-color: #ffffff !important;
}

.tooltip-inner {
    background-color: #eaeaea !important;
    color: #000000 !important;
}

.lrn-annotations-stickynote__content {
    background-color: #ffffff !important;
}
.lds-flexible-container__controls {
    background-color: #f7f7f7 !important;
}
.lrn-annotations-c-modal--stickynote .lds-btn:hover {
    background-color: #eaeaea !important;
}
.lds-btn:hover {
    border-color: #000000 !important;
}
.access-contrast-White-on-black.lrn-assess.lrn.lrn-assess .nav-tabs > .nav-tab {
    color: #fff;
}
.lrn-annotations-c-modal--stickynote .lds-btn-danger:hover {
    background-color: #b00808 !important;
}

.access-contrast-White-on-black.lrn-assess.lrn.lrn-assess .local-timer {
    color: #fff;
}

.lrn_selected {
    box-shadow: 0px 0px 2px 0px grey !important;
    // border: 2px solid #1a77b1 !important;
}

// tooltip border and bg color
.tooltip-inner {
    background-color: #eaeaea !important;
    color: #000000 !important;
    border: 2px solid #000000 !important;
}

    .learnosity-item {
        /* Scrollbar thumb (the draggable part) */
        &::-webkit-scrollbar-thumb {
            background: #c0dff4;
            border: 2px solid #000000; /* Outline effect */
            border-radius: 4px;
        }

        /* Hover effect */
        &::-webkit-scrollbar-thumb:hover {
            background: #c0dff4;
        }
    }
    .lrn.lrn-assess .lrn-vertical-toc .pagination-flagged.pagination-active>a:before {
        background-color: rgba(0,0,0,0) !important;
    }


// rich text editor
.lrn-wysihtml-toolbar,
.lrn-toolbar-footer {
    // background-color: #ffffff !important;
    border-color: #000000 !important;
}

.lrn_widget.lrn_formulaessayV2 .lrn-toolbar-footer {
    border-top: 1px solid #000000 !important;
}
.lrn_widget.lrn_formulaessayV2 .lrn-toolbar {
    border-bottom: 1px solid #000000 !important;
}

.lrn-horizontal-toc ul.pagination li[data-attempted=fully_attempted]:not(.pagination-active):before {
    content: unset !important;
}
// to add caret symbol for active pagination in deault color option
.lrn-assess.lrn.lrn-assess .lrn-bottom-region .toc .pagination-active a:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 3px;
    top: calc(50% - 3px);
    border-style: solid;
    border-width: 3px 0 3px 3px;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #1a77b1;
}
.lrn_selected{
    background-color: #eaeaea !important;
}

.lrn_widget.lrn_mcq .lrn_mcqgroup .lrn-mcq-option{
    border-left: unset !important;
    background-color: unset !important;
}

.lrn_widget .lrn_valid, .lrn_widget {
    background-color: unset !important;
}

// change token selected font to bold and border around it
.lrn_tokenhighlight_highlight{
    border: 2px solid #000000 !important;
    font-weight: bold !important;
    background-color: #eaeaea !important;
}
</style>
