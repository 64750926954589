const selectedAccessability = {};

export function learnosityEvents(inst, onItemLoad) {
    inst.api.assessApp().on("accessibility:change:color", (e) => {
        delete selectedAccessability.contrast;
        if (e) {
            selectedAccessability.contrast = e;
        }
    });

    inst.api.assessApp().on("accessibility:change:fontsize", (e) => {
        delete selectedAccessability.fontSize;
        if (e) {
            selectedAccessability.fontSize = e;
            applyStickyFontSize(e);
        }
    });

    inst.api.assessApp().on("test:submit:success", () => {
        inst.$debug.log("Learnosity API event - test:submit:success");
        inst.$axios
            .post(
                endpoint(
                    `/api/candidates/schedule-group/${inst.session.group.id}/events/submit-success`,
                ),
                {
                    session_id: inst.session.id,
                },
            )
            .catch((error) => inst.$global.globalAXIOSErrorHandler(error));

        inst.status = inst.api.getActivity()?.config?.navigation?.outro_item
            ? "outro"
            : "submitted";

        inst.save("submitted");
        inst.inform("delegate:test:submit:success", {});

        if (inst.session.proctor_external_session_reference) {
            inst.$axios
                .post(endpoint("/sanctum/candidates/revoke"), {})
                .then(() => {
                    inst.$global.disconnectFromChannels(true);
                    inst.$store.commit("clear", this);
                    inst.$router.push({
                        name: "login",
                    });
                })
                .catch(() => {
                    inst.$store.commit("clear", this);
                    inst.$router.push({
                        name: "login",
                    });
                });
        }
    });

    inst.api.assessApp().on("test:submit", () => {
        inst.$debug.log("Learnosity API event - test:submit");
        inst.inform("test:submit", {});
    });

    inst.api.on("test:finished:submit", () => {
        inst.$debug.log("Learnosity API event - test:finished:submit");
        inst.inform("test:finished:submit", {});
        inst.api.assessApp().off();
        inst.api.off();
        inst.$router.push({ name: "home" });
    });

    inst.api.assessApp().on("test:submit:error", () => {
        inst.$debug.log("Learnosity API event - test:submit:error");
        inst.$axios
            .post(
                endpoint(
                    `/api/candidates/schedule-group/${inst.session.group.id}/events/submit-failed`,
                ),
                {
                    session_id: inst.session.id,
                },
            )
            .catch((error) => inst.$global.globalAXIOSErrorHandler(error));
        inst.status = "submit_failed";
        inst.inform("test:submit:error", {});
    });

    inst.api.assessApp().on("test:save", () => {
        inst.$debug.log("Learnosity API event - test:save");
        inst.inform("test:save", {});
    });

    inst.api.assessApp().on("test:save:success", () => {
        inst.$debug.log("Learnosity API event - test:save:success");
        inst.saveLocal();
        inst.inform("test:save:success", {});
    });

    inst.api.assessApp().on("test:save:error", () => {
        inst.$debug.log("Learnosity API event - test:save:error");
        inst.saveLocal();
        inst.inform("test:save:error", {});
    });

    inst.api.assessApp().on("test:resume", () => {
        inst.$debug.log("Learnosity API event - test:resume");
        inst.resume();
        inst.inform("test:resume", {});
    });

    inst.api.assessApp().on("test:pause", () => {
        inst.$debug.log("Learnosity API event - test:pause");
        inst.inform("test:pause", {});
    });

    inst.api.on("item:load", () => {
        if (onItemLoad) {
            onItemLoad();
        }
    });

    inst.api.on("item:changed", (itemIndex) => {
        inst.$debug.log("Learnosity API event - item:changed");
        if (inst.state === "connected") {
            inst.$axios.post(
                endpoint(
                    `/api/candidates/schedule-group/${inst.session.group.id}/events/item-changed`,
                ),
                {
                    session_id: inst.session.id,
                    question: itemIndex + 1,
                },
            );
        }
        inst.save();
        inst.inform("delegate:item:changed", { question: itemIndex + 1 });
        window.scrollTo(0, 0);
    });

    inst.api.on("test:start", () => {
        inst.$debug.log("Learnosity API event - test:start");
        inst.$axios
            .post(
                endpoint(
                    `/api/candidates/schedule-group/${inst.session.group.id}/events/start-assessment`,
                ),
                {
                    session_id: inst.session.id,
                },
            )
            .then(function (response) {
                if (response.data.session) {
                    if (response.data.session.id === inst.session.id) {
                        inst.$debug.log(
                            "Message received from server of time sync:",
                            response.data.session,
                        );
                        inst.updateSessionTimes(
                            inst.session.id,
                            response.data.session,
                            inst.session,
                        );
                    }
                }
            })
            .catch((error) => inst.$global.globalAXIOSErrorHandler(error));
        inst.save();
        inst.inform("delegate:test:start", {});

        defaultCalcToDegrees();
    });

    timerAccessibilityColours(inst);
    learnosityEventsForSpellCheck(inst);
    scrollableStickyNotes(inst);
    moveAccessibilityToFront();
}

export function getAllColorPalletsOptions() {
    return [
        {
            name: "Grey on light grey",
            colors: {
                "content-color": "#575757",
                "content-color-subheading": "#666666",
                "content-color-toolbar": "#4f4f4f",
                "content-color-widget": "#424242",
                "content-color-hover": "#e0e0e0",
                "content-color-active": "#333333",
                "content-color-link": "#707070",
                "content-color-link-hover": "#858585",
                "content-color-link-visited": "#474747",
                "content-color-neutral": "#000000",
                "content-background": "#e0e0e0",
                "content-background-highlight": "#ffff99",
                "content-background-highlight-hover": "#e0e0e0",
                "content-background-correct": "#e7f4e1",
                "content-background-incorrect": "#fbdddd",
                "content-background-selected": "#c2c2c2",
                "content-border": "#ADADAD",
                "content-border-correct": "#62ae41",
                "content-border-incorrect": "#da1919",
                "content-border-focus": "#333333",
                "widget-background": "#e0e0e0",
                "widget-background-hover": "#B8B8B8",
                "widget-background-active": "#ADADAD",
                "widget-background-toolbar": "#D6D6D6",
                "well-color": "#5b5d5e",
                "well-color-highlight": "#424242",
                "well-color-grayed": "#4f4f4f",
                "well-color-toolbar": "#606263",
                "well-background": "#EBEBEB",
                "well-background-highlight": "#C2C2C2",
                "well-background-grayed": "#D6D6D6",
                "well-background-toolbar": "#e0e0e0",
                "progress-background": "#f5f5f5",
                "progress-color": "#ADADAD",
                "button-color": "#424242",
                "button-color-highlight": "#e0e0e0",
                "button-background": "#C2C2C2",
                "button-background-highlight": "#575757",
                "button-background-hover": "#ADADAD",
                "button-background-highlight-hover": "#474747",
            },
        },
        {
            name: "Black on soft yellow",
            colors: {
                "content-color": "#000000",
                "content-color-subheading": "#1E1E1E",
                "content-color-toolbar": "#141414",
                "content-color-widget": "#2C2C2C",
                "content-color-hover": "#000000",
                "content-color-active": "#303030",
                "content-color-link": "#2B2B2B",
                "content-color-link-hover": "#424242",
                "content-color-link-visited": "#555555",
                "content-color-neutral": "#000000",
                "content-background": "#fbedb7",
                "content-background-highlight": "#fdf6d1",
                "content-background-highlight-hover": "#fef9e5",
                "content-background-correct": "#f8f4c4",
                "content-background-incorrect": "#ffd1bf",
                "content-background-selected": "#fef5ca",
                "content-border": "#000000",
                "content-border-correct": "#efea93",
                "content-border-incorrect": "#ffae95",
                "content-border-focus": "#292929",
                "widget-background": "#fcecb7",
                "widget-background-hover": "#faf3d8",
                "widget-background-active": "#fdf6e2",
                "widget-background-toolbar": "#f1e6b9",
                "well-color": "#393939",
                "well-color-highlight": "#1F1F1F",
                "well-color-grayed": "#e8dbb0",
                "well-color-toolbar": "#000000",
                "well-background": "#f9f4d9",
                "well-background-highlight": "#fef5cd",
                "well-background-grayed": "#fcf9e8",
                "well-background-toolbar": "#fcecb7",
                "progress-background": "#fdf7e0",
                "progress-color": "#000000",
                "button-color": "#000000",
                "button-color-highlight": "#000000",
                "button-background": "#f7f1ce",
                "button-background-highlight": "#f7f1ce",
                "button-background-hover": "#f7f1ce",
                "button-background-highlight-hover": "#f7f1ce",
            },
        },
        {
            name: "Purple on light green",
            colors: {
                "content-color": "#a9306f",
                "content-color-subheading": "#CE5091",
                "content-color-toolbar": "#962b63",
                "content-color-widget": "#a9306f",
                "content-color-hover": "#C7E4D3",
                "content-color-active": "#fff5fb",
                "content-color-link": "#CE5091",
                "content-color-link-hover": "#DB80AF",
                "content-color-link-visited": "#7F2453",
                "content-color-neutral": "#a9306f",
                "content-background": "#C7E4D3",
                "content-background-highlight": "#c8e4d3",
                "content-background-highlight-hover": "#c8e4d3",
                "content-background-correct": "#e7f4e1",
                "content-background-incorrect": "#fbdddd",
                "content-background-selected": "#76bd93",
                "content-border": "#a9306f",
                "content-border-correct": "#62ae41",
                "content-border-incorrect": "#da1919",
                "content-border-focus": "#CE5091",
                "widget-background": "#c8e4d3",
                "widget-background-hover": "#50A573",
                "widget-background-active": "#3C7C56",
                "widget-background-toolbar": "#ACD7BE",
                "well-color": "#a9306f",
                "well-color-highlight": "#a9306f",
                "well-color-grayed": "#9e2d68",
                "well-color-toolbar": "#c43881",
                "well-background": "#E3F2E9",
                "well-background-highlight": "#BADEC9",
                "well-background-grayed": "#c9d8cf",
                "well-background-toolbar": "#c8e4d3",
                "progress-background": "#f5f5f5",
                "progress-color": "#CE5091",
                "button-color": "#a9306f",
                "button-color-highlight": "#73214b",
                "button-background": "#75BD93",
                "button-background-highlight": "#76bd93",
                "button-background-hover": "#76bd93",
                "button-background-highlight-hover": "#76bd93",
            },
        },
        {
            name: "Black on grayish green",
            colors: {
                "content-color": "#000000",
                "content-color-subheading": "#1E1E1E",
                "content-color-toolbar": "#141414",
                "content-color-widget": "#2C2C2C",
                "content-color-hover": "#000000",
                "content-color-active": "#303030",
                "content-color-link": "#2B2B2B",
                "content-color-link-hover": "#424242",
                "content-color-link-visited": "#555555",
                "content-color-neutral": "#000000",
                "content-background": "#ddedcb",
                "content-background-highlight": "#eaf3de",
                "content-background-highlight-hover": "#f2f9ea",
                "content-background-correct": "#e0f3c7",
                "content-background-incorrect": "#facfc2",
                "content-background-selected": "#e6f5d0",
                "content-border": "#000000",
                "content-border-correct": "#cae8a4",
                "content-border-incorrect": "#f8baa1",
                "content-border-focus": "#292929",
                "widget-background": "#ddeccb",
                "widget-background-hover": "#ebf5e0",
                "widget-background-active": "#f2f9ec",
                "widget-background-toolbar": "#d6e8c9",
                "well-color": "#393939",
                "well-color-highlight": "#1F1F1F",
                "well-color-grayed": "#bedcb5",
                "well-color-toolbar": "#000000",
                "well-background": "#f3fae7",
                "well-background-highlight": "#e7f3db",
                "well-background-grayed": "#f7fcf5",
                "well-background-toolbar": "#ddeccb",
                "progress-background": "#eff6e3",
                "progress-color": "#000000",
                "button-color": "#333333",
                "button-color-highlight": "#000000",
                "button-background": "#e2f0d4",
                "button-background-highlight": "#e2f0d3",
                "button-background-hover": "#e2f0d3",
                "button-background-highlight-hover": "#e2f0d3",
            },
        },
        {
            name: "Black on grayish red",
            colors: {
                "content-color": "#000000",
                "content-color-subheading": "#1E1E1E",
                "content-color-toolbar": "#141414",
                "content-color-widget": "#2C2C2C",
                "content-color-hover": "#000000",
                "content-color-active": "#303030",
                "content-color-link": "#2B2B2B",
                "content-color-link-hover": "#424242",
                "content-color-link-visited": "#555555",
                "content-color-neutral": "#000000",
                "content-background": "#f8ccc8",
                "content-background-highlight": "#f8ccc8",
                "content-background-highlight-hover": "#f8ccc8",
                "content-background-correct": "#ffd1cb",
                "content-background-incorrect": "#ffc1bf",
                "content-background-selected": "#f8c1bc",
                "content-border": "#000000",
                "content-border-correct": "#ff9691",
                "content-border-incorrect": "#ff6060",
                "content-border-focus": "#292929",
                "widget-background": "#f8ccc8",
                "widget-background-hover": "#f8d6d3",
                "widget-background-active": "#f9ebeb",
                "widget-background-toolbar": "#f2acab",
                "well-color": "#393939",
                "well-color-highlight": "#1F1F1F",
                "well-color-grayed": "#d0a6a9",
                "well-color-toolbar": "#000000",
                "well-background": "#ffe1df",
                "well-background-highlight": "#fcdada",
                "well-background-grayed": "#fef2f2",
                "well-background-toolbar": "#f8ccc8 ",
                "progress-background": "#ffdedc",
                "progress-color": "#000000",
                "button-color": "#000000",
                "button-color-highlight": "#000000",
                "button-background": "#f7c1bd",
                "button-background-highlight": "#f7c1bd",
                "button-background-hover": "#f7c1bd",
                "button-background-highlight-hover": "#f7c1bd",
            },
        },
        {
            name: "Black on violet",
            colors: {
                "content-color": "#000000",
                "content-color-subheading": "#1F1F1F",
                "content-color-toolbar": "#141414",
                "content-color-widget": "#212121",
                "content-color-hover": "#000000",
                "content-color-active": "#303030",
                "content-color-link": "#292929",
                "content-color-link-hover": "#3D3D3D",
                "content-color-link-visited": "#525252",
                "content-color-neutral": "#000000",
                "content-background": "#d1aad5",
                "content-background-highlight": "#ffff99",
                "content-background-highlight-hover": "#d1a9d5",
                "content-background-correct": "#e7f4e1",
                "content-background-incorrect": "#fbdddd",
                "content-background-selected": "#e1c8e4",
                "content-border": "#000000",
                "content-border-correct": "#62ae41",
                "content-border-incorrect": "#da1919",
                "content-border-focus": "#1F1F1F",
                "widget-background": "#d1a9d5",
                "widget-background-hover": "#E9D6EB",
                "widget-background-active": "#F0E4F2",
                "widget-background-toolbar": "#DABBDD",
                "well-color": "#383838",
                "well-color-highlight": "#141414",
                "well-color-grayed": "#aabbcc",
                "well-color-toolbar": "#000000",
                "well-background": "#F8F1F8",
                "well-background-highlight": "#DABBDD",
                "well-background-grayed": "#EBEBEB",
                "well-background-toolbar": "#d1a9d5",
                "progress-background": "#f5f5f5",
                "progress-color": "#000000",
                "button-color": "#000000",
                "button-color-highlight": "#000000",
                "button-background": "#E1C8E4",
                "button-background-highlight": "#E1C8E4",
                "button-background-hover": "#E1C8E4",
                "button-background-highlight-hover": "#E1C8E4",
            },
        },
        {
            name: "Black on soft blue",
            colors: {
                "content-color": "#000000",
                "content-color-subheading": "#1F1F1F",
                "content-color-toolbar": "#141414",
                "content-color-widget": "#212121",
                "content-color-hover": "#000000",
                "content-color-active": "#303030",
                "content-color-link": "#292929",
                "content-color-link-hover": "#3D3D3D",
                "content-color-link-visited": "#525252",
                "content-color-neutral": "#000000",
                "content-background": "#c0dff4",
                "content-background-highlight": "#c0dff4",
                "content-background-highlight-hover": "#c0dff4",
                "content-background-correct": "#DAEFFF",
                "content-background-incorrect": "#facddd",
                "content-background-selected": "#c8e7fb",
                "content-border": "#000000",
                "content-border-correct": "#62ae41",
                "content-border-incorrect": "#da1919",
                "content-border-focus": "#1F1F1F",
                "widget-background": "#c0dff4",
                "widget-background-hover": "#C7E3F3",
                "widget-background-active": "#D9EFF6",
                "widget-background-toolbar": "#AEC4D6",
                "well-color": "#383838",
                "well-color-highlight": "#141414",
                "well-color-grayed": "#aabbcc",
                "well-color-toolbar": "#000000",
                "well-background": "#EAF3F8",
                "well-background-highlight": "#C7E3F3",
                "well-background-grayed": "#F0F5F8",
                "well-background-toolbar": "#c0dff4",
                "progress-background": "#f0f8ff",
                "progress-color": "#000000",
                "button-color": "#000000",
                "button-color-highlight": "#000000",
                "button-background": "#B0D6F0",
                "button-background-highlight": "#b1d6f0",
                "button-background-hover": "#B0D6F0",
                "button-background-highlight-hover": "#B0D6F0",
            },
        },
        {
            "name": "Yellow on navy",
            "colors": {
                "content-color":                         "#fff25d",
                "content-color-subheading":              "#fff25d",
                "content-color-toolbar":                 "#f7e800",
                "content-color-widget":                  "#e8d900",
                "content-color-hover":                   "#fff25d",
                "content-color-active":                  "#ccbf00",
                "content-color-link":                    "#FFF585",
                "content-color-link-hover":              "#FFF799",
                "content-color-link-visited":            "#FFEC1F",
                "content-color-neutral":                 "#e8d804",
                "content-background":                    "#2d4e84",
                "content-background-highlight":          "#ffff99",
                "content-background-highlight-hover":    "#fcfcd3",
                "content-background-correct":            "#e7f4e1",
                "content-background-incorrect":          "#fbdddd",
                "content-background-selected":           "#243f6a",
                "content-border":                        "#fff25d",
                "content-border-correct":                "#62ae41",
                "content-border-incorrect":              "#da1919",
                "content-border-focus":                  "#fff25d",
                "widget-background":                     "#2e4e84",
                "widget-background-hover":               "#1F365B",
                "widget-background-active":              "#1A2D4C",
                "widget-background-toolbar":             "#2A487A",
                "well-color":                            "#fff25d",
                "well-color-highlight":                  "#d9cb00",
                "well-color-grayed":                     "#000000",
                "well-color-toolbar":                    "#fff25d",
                "well-background":                       "#FFFCD6",
                "well-background-highlight":             "#1F365B",
                "well-background-grayed":                "#EBEBEB",
                "well-background-toolbar":               "#2e4e84",
                "progress-background":                   "#f5f5f5",
                "progress-color":                        "#fff25d",
                "button-color":                          "#e8d900",
                "button-color-highlight":                "#fff25d",
                "button-background":                     "#243F6A",
                "button-background-highlight":           "#243F6A",
                "button-background-hover":               "#1A2D4C",
                "button-background-highlight-hover":     "#1A2D4C"
            }
        },
        {
            "name": "White on black",
            "colors": {
                "content-color":                         "#ffffff",
                "content-color-subheading":              "#EBEBEB",
                "content-color-toolbar":                 "#000000",
                "content-color-widget":                  "#ffffff",
                "content-color-hover":                   "#000000",
                "content-color-active":                  "#000000",
                "content-color-link":                    "#EBEBEB",
                "content-color-link-hover":              "#F5F5F5",
                "content-color-link-visited":            "#D6D6D6",
                "content-color-neutral":                 "#000000",
                "content-background":                    "#000000",
                "content-background-highlight":          "#ffff99",
                "content-background-highlight-hover":    "#ffffff",
                "content-background-correct":            "#e7f4e1",
                "content-background-incorrect":          "#fbdddd",
                "content-background-selected":           "#dddddd",
                "content-border":                        "#FFFFFF",
                "content-border-correct":                "#62ae41",
                "content-border-incorrect":              "#da1919",
                "content-border-focus":                  "#f0f0f0",
                "widget-background":                     "#000000",
                "widget-background-hover":               "#e8e8e8",
                "widget-background-active":              "#f0f0f0",
                "widget-background-toolbar":             "#4d4d4d",
                "well-color":                            "#FFFFFF",
                "well-color-highlight":                  "#424242",
                "well-color-grayed":                     "#4f4f4f",
                "well-color-toolbar":                    "#FFFFFF",
                "well-background":                       "#E0E0E0",
                "well-background-highlight":             "#C2C2C2",
                "well-background-grayed":                "#D6D6D6",
                "well-background-toolbar":               "#ffffff",
                "progress-background":                   "#f5f5f5",
                "progress-color":                        "#858585",
                "button-color":                          "#000000",
                "button-color-highlight":                "#000000",
                "button-background":                     "#dddddd",
                "button-background-highlight":           "#ffffff",
                "button-background-hover":               "#CCCCCC",
                "button-background-highlight-hover":     "#EBEBEB"
            }
        },
    ];
}

export function learnosityAccessibilityPalettes(inst) {
    // We add a simple limited custom palette called blue paper
    inst.api.assessApp().addAccessibilityPalettes(getAllColorPalletsOptions());
}

export function timerAccessibilityColours(inst) {
    let accessibilityEl = document.querySelector(".test-accessibility");
    if (!accessibilityEl) {
        return;
    }
    const assessment = document.querySelector(".lrn-assess");
    const options = {
        attributes: true,
    };
    let observer = new MutationObserver(function (mutationList) {
        let accessibilityClass = "";
        let classMutation = false;
        mutationList.forEach(function (mutation) {
            if (
                mutation.type === "attributes" &&
                mutation.attributeName === "class"
            ) {
                classMutation = true;
                accessibilityClass = Array.from(mutation.target.classList).find(
                    (className) => className.match(/access-contrast-/),
                );
            }
        });
        if (!classMutation) {
            return;
        }
        let timerEl = document.querySelector(".local-timer");
        let existingAccessibilityClass = Array.from(timerEl.classList).find(
            (className) => className.match(/access-contrast-/),
        );
        if (existingAccessibilityClass) {
            timerEl.classList.remove(existingAccessibilityClass);
        }
        setTimeout(function () {
            //Need timeout to prevent the above removing the class we add here
            if (accessibilityClass) {
                timerEl.classList.add(accessibilityClass);
            }
        }, 100);
    });

    accessibilityEl.addEventListener("click", function () {
        observer.observe(assessment, options);
    });

    inst.api.assessApp().on("test:panel:hide", () => {
        saveAccessability(inst);
        // need timeout here otherwise when the cancel button pressed it misses the class reverting
        setTimeout(function () {
            observer.disconnect();
        }, 300);
    });
}

export function saveAccessability(inst) {
    // Function to format contrast string
    const formatContrast = (contrast) => {
        return contrast
            .replace("access-contrast-", "")
            .replaceAll("-", " ")
            .toLowerCase()
            .replace(/^[a-z]/, (char) => char.toUpperCase());
    };

    // Filter and format accessibility settings
    if (selectedAccessability.contrast) {
        selectedAccessability.contrast = formatContrast(
            selectedAccessability.contrast,
        );
    }

    // If there are any valid settings, send the PUT request
    if (Object.keys(selectedAccessability).length > 0) {
        inst.$axios
            .put(endpoint("/api/candidates/save-accessability"), {
                accessability_settings: selectedAccessability,
            })
            .catch((error) => inst.$global.globalAXIOSErrorHandler(error));
    }
}

export function defaultCalcToDegrees() {
    let calc = document.querySelector(
        ".lrn_float_element_container .lrn_calculator",
    );
    if (calc) {
        let button = calc.querySelector(".lrn_calc_row .lrn_calc_cover_deg");
        if (button) {
            button.click();
        }
    }
}

export function moveAccessibilityToFront() {
    const menu = document.querySelector(".menu-mask .menu-buttons");
    if (menu) {
        // Select the accessibility element
        const accessibility = menu.querySelector(".test-accessibility");

        if (accessibility) {
            // Remove the accessibility element
            accessibility.remove();

            // Add the accessibility element back to the beginning of the menu
            menu.prepend(accessibility);
        }
    }
}

/**
 * @param inst
 */
export function scrollableStickyNotes(inst) {
    let collapsedHeight = 44;
    let currentItem;
    let scrollablePassage;
    let scrollablePassageContent;
    let scrollablePassageMargins = 0;
    let lrnContent;
    let scrollableStickyNotes = {};
    let checkBounds = false;
    let top = 0;
    let currentScrollAmount;
    let positions = {};
    let notes = [];
    let noteHeights = [];
    let noteVisibilityToggle = true;

    let noteHeight = function (note) {
        if (note.isExpanded()) {
            return note.getDimensions().height;
        }
        return collapsedHeight;
    };

    let setNoteInternalPosition = function (note) {
        //y position of note, it is a % of the learnosity content area
        let posY = note.getPosition().y.slice(0, -1);
        if (note.isVisible()) {
            //Note is visible so use its current y% position and add the currentScrollAmount
            positions[note.getId()] =
                posY - (100 * currentScrollAmount) / lrnContent.clientHeight;
            return;
        }
        if (!positions[note.getId()]) {
            positions[note.getId()] = posY;
        }
        //Note has been scrolled off visible content so add the scroll amount to the last saved position for this note
        positions[note.getId()] =
            positions[note.getId()] -
            (100 * currentScrollAmount) / lrnContent.clientHeight;
    };

    let setCurrentItem = function () {
        if (currentItem !== inst.api.getCurrentItem().reference) {
            currentItem = inst.api.getCurrentItem().reference;
            scrollablePassage = document.querySelector(
                `[data-reference="${currentItem}"] .lrn-assess-content .lrn_scrollablepassage .lrn-viewport`,
            );
            lrnContent = document.querySelector(
                `[data-reference="${currentItem}"] .lrn-assess-content`,
            );
            if (!scrollablePassage || !lrnContent) {
                return false;
            }
            top = scrollablePassage.scrollTop;
            scrollablePassageContent =
                scrollablePassage.querySelector(".lrn_widget");
            if (scrollablePassageContent) {
                let scrollablePassageStyle =
                    scrollablePassageContent.currentStyle ||
                    window.getComputedStyle(scrollablePassageContent);
                scrollablePassageMargins =
                    parseInt(
                        scrollablePassageStyle.marginTop.substring(
                            0,
                            scrollablePassageStyle.marginTop.length - 2,
                        ),
                    ) +
                    parseInt(
                        scrollablePassageStyle.marginBottom.substring(
                            0,
                            scrollablePassageStyle.marginBottom.length - 2,
                        ),
                    );
            }
        }
        return true;
    };

    let setNoteVisibilityAndPosition = function (setInternalPosition) {
        notes.forEach(function (note) {
            if (
                note.getCollectionId() === currentItem &&
                scrollableStickyNotes[currentItem] &&
                scrollableStickyNotes[currentItem].length > 0 &&
                scrollableStickyNotes[currentItem].indexOf(note.getId()) !== -1
            ) {
                if (setInternalPosition ?? false) {
                    setNoteInternalPosition(note);
                }

                //position of scroll in %
                let scrollposition =
                    (100 *
                        (Math.ceil(scrollablePassage.scrollTop) +
                            scrollablePassage.clientHeight -
                            scrollablePassageMargins)) /
                    scrollablePassageContent.clientHeight;

                if (
                    positions[note.getId()] <= 0 ||
                    (noteHeights[note.getId()] + positions[note.getId()] >=
                        100 &&
                        scrollposition < 100) ||
                    !noteVisibilityToggle
                ) {
                    note.hide();
                    return;
                } else {
                    note.show();
                }
                note.setPosition(
                    note.getPosition().x,
                    positions[note.getId()] + "%",
                );
            }
        });
    };

    let setStickNotesInScrollablePassage = function () {
        /**
         * Only need to check the note is in the scrolling area if a note is moved or resized,
         * so we do it once then skip the next xxx milliseconds of scrolling
         */
        if (!checkBounds) {
            checkBounds = true;

            if (!scrollableStickyNotes[currentItem]) {
                scrollableStickyNotes[currentItem] = [];
            }
            notes.forEach(function (note) {
                if (note.getCollectionId() !== currentItem) {
                    return;
                }
                let notePosLeft =
                    (lrnContent.clientWidth *
                        note.getPosition().x.slice(0, -1)) /
                    100;
                let notePosRight = notePosLeft + note.getDimensions().width;
                let notePosTop =
                    (lrnContent.clientHeight *
                        note.getPosition().y.slice(0, -1)) /
                    100;
                let notePosBottom = notePosTop + noteHeight(note);
                if (
                    notePosLeft > scrollablePassage.clientWidth ||
                    notePosRight <
                        scrollablePassage.getBoundingClientRect().x -
                            lrnContent.getBoundingClientRect().x ||
                    notePosTop > scrollablePassage.clientHeight ||
                    notePosBottom <
                        scrollablePassage.getBoundingClientRect().y -
                            lrnContent.getBoundingClientRect().y
                ) {
                    if (
                        scrollableStickyNotes[currentItem].indexOf(
                            note.getId(),
                        ) !== -1
                    ) {
                        scrollableStickyNotes[currentItem].splice(
                            scrollableStickyNotes[currentItem].indexOf(
                                note.getId(),
                            ),
                            1,
                        );
                    }
                } else {
                    if (
                        scrollableStickyNotes[currentItem].indexOf(
                            note.getId(),
                        ) === -1
                    ) {
                        scrollableStickyNotes[currentItem].push(note.getId());
                    }
                    noteHeights[note.getId()] =
                        (100 * noteHeight(note)) / lrnContent.clientHeight;
                }
            });

            setTimeout(function () {
                checkBounds = false;
            }, 500);
        }
    };

    let stickyNoteToggle = document.querySelector(
        ".lrn-assess .lrn_btn_stickynote",
    );
    if (stickyNoteToggle) {
        document
            .querySelector(
                ".lrn-assess .assess-dropdown .dropdown-menu, .lrn-assess .menu-mask",
            )
            ?.addEventListener("click", function (ev) {
                let button = ev.target.closest("button");
                if (button && button.classList.contains("lrn_btn_stickynote")) {
                    let use = button.querySelector("use");
                    if (
                        use &&
                        use.getAttribute("xlink:href").match("show-hide")
                    ) {
                        if (button.classList.contains("lrn_active")) {
                            noteVisibilityToggle = false;
                            return;
                        }
                        noteVisibilityToggle = true;
                        setTimeout(function () {
                            setNoteVisibilityAndPosition();
                        }, 20);
                    }
                }
            });
    }

    inst.api.annotationsApp()?.on("stickynote:add", function () {
        setTimeout(function () {
            applyStickyFontSize(selectedAccessability.fontSize);
        }, 100);

        if (!setCurrentItem()) {
            return;
        }
        setNoteVisibilityAndPosition();
    });
    inst.api.on("item:changed", function () {
        if (notes.length === 0 || !setCurrentItem()) {
            return;
        }
        /**
         * All notes are made visible when an item changes
         * We need to hide them after that logic
         */
        setTimeout(function () {
            notes.forEach(function (note) {
                if (
                    scrollableStickyNotes[currentItem] &&
                    scrollableStickyNotes[currentItem].length > 0 &&
                    scrollableStickyNotes[currentItem].indexOf(note.getId()) !==
                        -1
                ) {
                    noteHeights[note.getId()] =
                        (100 * noteHeight(note)) / lrnContent.clientHeight;
                    if (!positions[note.getId()]) {
                        return;
                    }

                    if (
                        positions[note.getId()] <= 0 ||
                        noteHeights[note.getId()] + positions[note.getId()] >=
                            100
                    ) {
                        note.hide();
                    }
                }
            });
        }, 20);
    });
    document
        .querySelectorAll(
            ".lrn-assess-content .lrn_scrollablepassage .lrn-viewport",
        )
        .forEach(function (el) {
            el.addEventListener("scroll", function () {
                notes = inst.api
                    .annotationsApp()
                    .module("stickynote")
                    .getAllNotes();

                if (notes.length === 0 || !setCurrentItem()) {
                    return;
                }

                setStickNotesInScrollablePassage();

                currentScrollAmount = scrollablePassage.scrollTop - top;
                top = scrollablePassage.scrollTop;
                setNoteVisibilityAndPosition(true);
            });
        });
}

export function learnosityEventsForSpellCheck(inst) {
    // Set up a listener on item load for any Plain Text or Essay question types
    inst.api.on("item:load", function () {
        setupGrammarlyBlocker();
    });

    // Set up a listener for the Annotations API notepad
    inst.api.annotationsApp()?.on("notepad:toggleVisibility", function () {
        setupGrammarlyBlocker();
    });

    // Set up a listener for any Annotations API sticky notes
    inst.api.annotationsApp()?.on("stickynote:add", function () {
        setupGrammarlyBlocker();
    });
}

function setupGrammarlyBlocker() {
    var $els = [];
    var $elTextareas = [];
    // CSS classname added to Learnosity plain text and essay question types
    var elementClassnames = ["lrn_texteditor_editable"];
    // Parent Items API element
    var $elLearnosityNode = document.getElementById("learnosity_assess");

    // Look for found plain text or essay question types
    for (var i = 0; i < elementClassnames.length; i++) {
        $els = $elLearnosityNode.getElementsByClassName(elementClassnames[i]);
        if ($els.length) {
            for (var j = 0; j < $els.length; j++) {
                addBlockingAttributes($els[j]);
            }
        }
    }

    // Look for any textareas inside the Learnosity Items API
    $elTextareas = $elLearnosityNode.getElementsByTagName("textarea");
    for (var l = 0; l < $elTextareas.length; l++) {
        addBlockingAttributes($elTextareas[l]);
    }
}

let removeClassStartingWith = (element, prefix) => {
    const classes = element.className.split(" ");

    classes.forEach((className) => {
        if (className.startsWith(prefix)) {
            element.classList.remove(className);
        }
    });
};

let applyStickyFontSize = (fontsize) => {
    const stickyElements = document.querySelectorAll(
        ".lrn-annotations-stickynote__content.lds-flexible-container__content .lrn-annotations-stickynote__textarea",
    );

    stickyElements.forEach((element) => {
        removeClassStartingWith(element, "access-sticky");
        switch (fontsize) {
            case "small":
                element.classList.add("access-sticky-small");
                break;

            case "normal":
                element.classList.add("access-sticky-normal");
                break;

            case "large":
                element.classList.add("access-sticky-l");
                break;

            case "xlarge":
                element.classList.add("access-sticky-xl");
                break;

            case "xxlarge":
                element.classList.add("access-sticky-xxl");
                break;
            default:
        }
    });
};
/**
 * Add custom (grammar blocking) attributes to the passed
 * HTML DOM element
 */
function addBlockingAttributes($el) {
    $el.setAttribute("data-gramm", "false");
    $el.setAttribute("data-gramm_editor", "false");
    $el.setAttribute("data-enable-grammarly", "false");
    $el.setAttribute("spellcheck", "false");
    $el.setAttribute("autocorrect", "false");
    $el.setAttribute("autocomplete", "false");
    $el.setAttribute("autocapitalize", "false");
}
